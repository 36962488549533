import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './services/authservice/authservice';
import { GlobalService } from './services/authservice/globalservice';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { GetApiurl } from './parameters';


@Injectable()
export class AppInit {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(
    private http: HttpClient,
    private gs: GlobalService
  ) {

  }
  load() {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      let url = GetApiurl('api-token-refresh/');
      let token_data = `{"token":"${token}"}`;
      return new Promise((resolve, reject) => {
        this.http
          .post(url, token_data, { headers: this.headers })
          .pipe(map(Response => Response))
          .subscribe((response: any) => {
            localStorage.setItem('jwt_token', response['token']);
            this.gs.setUser(response['user']);
            resolve(true);
          },
            error => {
              console.log(error);
              this.gs.setUser(null);
              localStorage.clear();
              resolve(true);
            });
      });
    }

  }
}
