import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { timer } from 'rxjs';

@Injectable()
export class GlobalService {

    private userSource = new BehaviorSubject<any>(null);
    user$ = this.userSource.asObservable();
    private userPermissionsSource = new BehaviorSubject<Array<string>>([]);
    userPermissions$ = this.userPermissionsSource.asObservable();

    private toastMessageSource = new BehaviorSubject<Array<string>>(['', 'success']);
    toastMessage = this.toastMessageSource.asObservable();

    private subServiceSource = new BehaviorSubject<any>('');
    subService$ = this.subServiceSource.asObservable();

    private userNameSource = new BehaviorSubject<any>(undefined);
    userDetails$ = this.userNameSource.asObservable()

    private latLanDetails = new BehaviorSubject<any>({});  
    lat_lan$ = this.latLanDetails.asObservable();

    constructor() {
    }

    //setSubService
    setSubService(subservice) {
        this.subServiceSource.next(subservice)
    }

    // Set or Change user value
    setUser(user) {
        if (user instanceof Object) {
            this.userPermissionsSource.next(user.permissions);
            this.userSource.next(user);
        }
        else {
            this.userSource.next(null);
            this.userPermissionsSource.next([]);
        }
    }
    // Toast message
    setToastMessage(message: string, time: number, state?: string) {
        this.toastMessageSource.next([message, state || 'success']);
        time = time < 10000 && time != 0 ? 10000 : time;
        let timer$ = timer(time);

        timer$.subscribe(() => {
            this.toastMessageSource.next(['', 'success']);
        });
    }

    // Global function to refresh
    private globalSource = new BehaviorSubject<boolean>(false);
    global$ = this.globalSource.asObservable();
    refreshValue() {
        this.globalSource.next(true)
    }

    // Update User name
    updateName(id) {
        this.userNameSource.next(id)
    }

     //Lat Lng
  public passLatlng(data){     
    if (data instanceof Object) {       
        this.latLanDetails.next(data);
    }     
  }
}
