import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ReminderService {

    constructor(private http: HttpClient) { }
    // ticket-master/
    public getReminderDetails(data) {
        const url: string = GetApiurl('reminder/get-reminder-details/');

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public getVendorDailyJobs(data) {
        const url: string = GetApiurl('reminder/get-vendor-today-jobs/');

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

}
