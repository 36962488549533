import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-cell-renderer',
    templateUrl: './cell-renderer.component.html',
    styleUrls: ['./cell-renderer.component.scss']
})
export class CellRendererComponent implements OnInit, ICellRendererAngularComp {
    public params: any;
    header: any;
    templateRef: any;
    table: string;

    constructor() { }

    ngOnInit() {
    }

    public rowEvent(action: string) {
        this.params.context.gridRenderer.emitRowEvent({
            action: action,
            data: this.params.data
        });
    }

    agInit(params: any): void {
        this.params = params;
        this.header = params.colDef.headerName;
        this.templateRef = params.context.cellRenderer;
        this.table = params.context.table;
    }

    refresh(): boolean {
        return false;
    }

}