export class ValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any, controlName?: string) {

        const config = {
            'required': `Please enter ${ValidationService.formatControlName(controlName)}`,
            'invalidEmailAddress': 'Please enter valid email address',
            'invalidPhoneNumber': 'Mobile number must be 10 digits.',
            'invalidNonEmptyList': 'Please select atleast one value.',
            'notEqual': 'New password and confirm password does not match',
            'invalidPassword': 'Password should contain atleast one digit',
            'invalidPincode': 'Please enter valid pincode(six digits)',
            'invalidPass': 'Password should contain atleast 1 number and Minimum 6 digits'
        };

        // Returning the error message
        return config[validatorName];
    }

    // For removing the underscore from name
    static formatControlName(name: string) {
        if (name) {
            return name.replace(/_/g, ' ');
        } else {
            return 'input';
        }
    }

    // Email Validator
    static emailValidator(control) {
        // RFC 2822 compliant regex
        if ((!control.value) || control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {
            return { 'invalidEmailAddress': true };
        }
    }

    static passwordValidator(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }
    // Password validation
    static passwordValidation(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if ((!control.value) || control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*_-]{6,100}$/)) {
            return null;
        } else {
            return { 'invalidPass': true };
        }
    }

    static equalValidator(group) {
        // Checks all values of controls in group are equal
        let controlNames: Array<string> = Object.keys(group.value);
        let first: string = controlNames.splice(0, 1)[0];

        for (let controlName of controlNames) {
            if (group.controls[first].touched && group.controls[controlName].touched && group.value[controlName] != group.value[first]) {
                return { 'notEqual': true };
            }
        }
        return null;
    }

    // Non empty validator
    static nonEmptylistValidator(control) {
        // Checks is array & has Minimum one item in array
        if (control.value instanceof Array && (control.value.length > 0)) {
            return null;
        } else {
            return { 'invalidNonEmptyList': true };
        }
    }

    // Phone Validator
    static phoneValidator(control) {
        var phoneNumber = /^\d{10}$/;
        if (control.value != null && control.value.match(phoneNumber) || null) {
            return null;
        }
        else {
            return { 'invalidPhoneNumber': true };
        }
    }

    static pincodeValidator(control){
        var pincode = /^\d{6}$/;
        if (control.value != null && control.value.match(pincode) || null) {
            return null;
        }
        else {
            return { 'invalidPincode': true };
        }
    }
    // Email validation

    static emailValidation(element) {
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(element)) {
            return true
        }
        else {
            return false
        }

    }
    

    // Mobile Number validation

    static mobileValidation(element) {
        if (element.length == 10) {
            return false
        }
        else {
            return true
        }

    }
    
}
