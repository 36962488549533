import { Component, OnInit, Input, Output, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { GetApiurl } from 'src/app/parameters';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {

  errorMessage = null;
  title: string;
  content: string;
  remoteUrl: string;
  table: any;
  method = 'DELETE';
  request_obj: any;
  comments;
  show_comments = false;
  constructor(
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    private http: HttpClient,
    public Dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data['title'];
    this.content = data['content'];
    this.remoteUrl = data['remoteUrl'];
    this.table = data['table'];
    if ('method' in data) {
      this.method = data['method'];
    }
    if ('request_obj' in data) this.request_obj = data['request_obj'];
    if ('show_comments' in data) this.show_comments = data['show_comments'];
  }
  ngOnInit() {
  }

  // Error Handling
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body: any = error.json() || '';
      const err = body.error || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.error ? error.error : error.toString();
    }
    console.error(errMsg);
    return throwError(errMsg);
  }

  openDialog(): void {
    this.Dialog.open(DeleteModalComponent, {
      width: '400x',
      data: {}
    });
  }

  onSubmit(): void {
    if (this.method == 'DELETE') {
      this.http
        .delete(GetApiurl(this.remoteUrl))
        .pipe(map(Response => Response), catchError(this.handleError))
        .subscribe(
          Result => {
            this.dialogRef.close(true);
            if (this.table) this.table.refreshTable();
          },
          error => {            
            if (this.data['errorMessage'] != null) {
              this.errorMessage = this.data['errorMessage'];
            }
            else {
              this.errorMessage = error['msg'];
            }
          }
        );
    }
    else if (this.method == 'POST') {
      if (this.show_comments) this.request_obj['comments'] = this.comments;
      this.http
        .post(GetApiurl(this.remoteUrl), this.request_obj)
        .pipe(map(Response => Response), catchError(this.handleError))
        .subscribe(
          Result => {
            this.dialogRef.close(true);
            if (this.table) this.table.refreshTable();
          },
          error => {
            if (this.data['errorMessage'] != null) {
              this.errorMessage = this.data['errorMessage'];
            }
            else this.errorMessage = error['msg'];
          }
        );
    }
    else if (this.method == 'GET') {
      this.http
        .get(GetApiurl(this.remoteUrl))
        .pipe(map(Response => Response), catchError(this.handleError))
        .subscribe(
          Result => {
            this.dialogRef.close(true);
            if (this.table) this.table.refreshTable();
          },
          error => {
            if (this.data['errorMessage'] != null) {
              this.errorMessage = this.data['errorMessage'];
            }
            else this.errorMessage = error['msg'];
          }
        );
    }
    else if (this.method == 'PUT') {
      if (this.show_comments) this.request_obj['comments'] = this.comments;
      this.http
        .put(GetApiurl(this.remoteUrl), this.request_obj)
        .pipe(map(Response => Response), catchError(this.handleError))
        .subscribe(
          Result => {
            this.dialogRef.close(true);
            if (this.table) this.table.refreshTable();
          },
          error => {
            if (this.data['errorMessage'] != null) {
              this.errorMessage = this.data['errorMessage'];
            }
            else this.errorMessage = error['msg'];
          }
        );
    }

  }

}
