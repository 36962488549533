import { Component, Input, OnInit } from '@angular/core';
import { customBread } from "./interface";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-custom-breadcrumb',
  templateUrl: './custom-breadcrumb.component.html',
  styleUrls: ['./custom-breadcrumb.component.scss']
})
export class CustomBreadcrumbComponent implements OnInit {

  custom_data: Array<customBread> = [];
  custom_data_show: boolean = false;
  route_data: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.data.subscribe(res => {
      if (res['breadcrumb'] instanceof Array) {
        this.custom_data = res['breadcrumb'];
      }
    })
  }

  ngOnInit() {
  }

  @Input('customData')
  set customData(data: Array<any>) {
    if (data.length > 0) {
      this.custom_data = data;
      this.custom_data_show = true;
    }
  }

  navigation(url) {
    this.router.navigate([url]);
  }

}
