export class User {
    constructor(
        public id: number,
        public email: string,
        public mobile: string,
        public first_name?: string,
        public password?: string,
        public last_name?: string,
        public address?: string,
        public permissions?: any,
        public accesses?: any,
        public is_superuser?: Boolean,
       public user_type?: string                      
    ) { }
}

export class Roles {
    id: number;
    name: string;
    alias: string;
    created_by: string;
    accesses: any;
    description: string = '';
    created_at: Date;
    modified_at: Date; 

    constructor(data?: any) {
        if(data) {
            this.description = data['description'] || this.description;
        }
    }
}

