import { JwtInterceptor } from './../services/jwt-interceptor.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesComponent } from '../directives/control-messages.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AgmDirectionModule } from 'agm-direction';
import { PipesModule } from '../pipes';
import { GenericsService } from '../services/generics.service';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { NgxDatatableModule, DatatableComponent } from '@swimlane/ngx-datatable';

import { AccessDirective } from './access.directive';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomDatatableComponent } from './custom-datatable/custom-datatable.component';
import { MatTableModule, MatSortModule } from '@angular/material'
import { FlexLayoutModule } from '@angular/flex-layout';
import { TicketingDatatableComponent } from './ticketing-datatable/ticketing-datatable.component';
import { MatSelectModule } from '@angular/material/select';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { NormalColumn } from './index';
import { CommonMatModelComponent, SuccessModelComponent } from './common-mat-model/common-mat-model.component';
import { MatMenuModule } from '@angular/material/menu';
import { GridDatatableModule } from './grid-datatable/grid-datatable.module';

import {
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatCheckboxModule
} from '@angular/material';
import { CustomerBalanceWidgetComponent } from './customer-balance-widget/customer-balance-widget.component';
import { CommonTransactionModelComponent } from './common-transaction-model/common-transaction-model.component';
import { CustomBreadcrumbComponent } from './custom-breadcrumb/custom-breadcrumb.component';



@NgModule({
    declarations: [
        ControlMessagesComponent,
        AccessDirective,
        CustomDatatableComponent,
        TicketingDatatableComponent,
        DeleteModalComponent,
        NormalColumn,
        CommonMatModelComponent,
        CustomerBalanceWidgetComponent,
        SuccessModelComponent,
        CommonTransactionModelComponent,
        CustomBreadcrumbComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AgmDirectionModule,
        Ng2TableModule,
        PipesModule,
        NgxDatatableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatTableModule,
        MatSortModule,
        FlexLayoutModule,
        MatGridListModule,
        MatIconModule,
        MatSelectModule,
        MatMenuModule,
        GridDatatableModule,
        MatCheckboxModule
    ],
    exports: [
        // Directives
        ControlMessagesComponent,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AgmDirectionModule,
        Ng2TableModule,
        DatatableComponent,
        NgxDatatableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        AccessDirective,
        MatDialogModule,
        CustomDatatableComponent,
        TicketingDatatableComponent,
        DeleteModalComponent,
        NormalColumn,
        PipesModule,
        CommonMatModelComponent,
        GridDatatableModule,
        CustomerBalanceWidgetComponent,
        SuccessModelComponent,
        CommonTransactionModelComponent,
        CustomBreadcrumbComponent
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        GenericsService,
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] }
    ],
    entryComponents: [
        DeleteModalComponent,
        CommonMatModelComponent,
        SuccessModelComponent,
        CommonTransactionModelComponent
    ]
})

export class DirectiveModule { }
