import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

@Component({
    selector: 'app-common-mat-model',
    templateUrl: './common-mat-model.component.html',
    styleUrls: ['./common-mat-model.component.scss']
})
export class CommonMatModelComponent {

    constructor(
        private dialogRef: MatDialogRef<CommonMatModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    Close() {
        this.dialogRef.close(true);
    }
    confirm(flag) {
        this.dialogRef.close(flag);
    }

}
@Component({
    selector: 'app-success-model-component',
    templateUrl: './success-model.component.html',
    styleUrls: ['./common-mat-model.component.scss']
})
export class SuccessModelComponent {
    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<SuccessModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    Close() {
        this.dialogRef.close(true);
    }
}