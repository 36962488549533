import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-header-renderer',
    templateUrl: './header-renderer.component.html',
    styleUrls: ['./header-renderer.component.scss']
})
export class HeaderRendererComponent implements OnInit, IHeaderAngularComp {
    public params: any;
    header: any;
    templateRef: any;
    table: string;

    constructor() { }

    ngOnInit() {
    }

    agInit(params: any): void {
        this.params = params;
        this.header = params.column.colDef.headerName;
        this.templateRef = params.context.headerRenderer;
        this.table = params.context.table;
    }

    headerEvent() {
        this.params.context.gridRenderer.emitHeaderEvent({
            action: 'create'
        });
    }

}