import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { catchError } from 'rxjs/operators';

// components
import { CellRendererComponent } from './cell-renderer/cell-renderer.component';
import { HeaderRendererComponent } from './header-renderer/header-renderer.component';

// interfaces
import { GridConfig, Columns, Renderers, RowEvent, HeaderEvent } from './grid-datatable';

import { SearchList } from '../extended-search/slim-search/slim-search';
import { GetApiurl } from 'src/app/parameters';

@Component({
    selector: 'grid-datatable',
    templateUrl: './grid-datatable.component.html',
    styleUrls: ['./grid-datatable.component.scss']
})
export class GridDatatableComponent implements OnInit {

    // columns
    columnTog = [];
    columnDefs: Array<any> = [];
    cellRenderer: Object = {};
    headerRenderer: Object = {};

    // rows
    rowData: Array<any> = [];
    tempRowData: Array<any> = [];

    // sidepan
    sidepan: boolean = false;
    sidepanMenu: Array<string> = [];
    enableSidepan: boolean = false;

    // searchbox
    search: boolean = false;
    searchUrl: Object = {};
    searchList: SearchList[] = [];

    // table configs
    config: GridConfig;
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
    frameworkComponents: { cellRenderer: any, agColumnHeader?: any };
    context: { gridRenderer: any, cellRenderer?: Object, headerRenderer?: Object };
    subscriptions: Object = {};

    // template reference
    @ViewChild('agGrid', { static: false }) agGrid: AgGridAngular;

    // events
    @Output('rowEvent') rowEvent: EventEmitter<any> = new EventEmitter();
    @Output('headerEvent') headerEvent: EventEmitter<any> = new EventEmitter();

    // get configuration for table and search
    @Input('config')
    set tableConfig(config: GridConfig) {
        this.search = config.search;
        this.searchUrl = config.searchUrl;
        this.searchList = config.searchList.concat([]);
        this.sidepan = config.sidepan;
        this.sidepanMenu = config.sidepanMenu;
        this.config = config;
        this.retrieveData();
    }

    // get cell and header renderers
    @Input('renderers')
    set renderers(renderer: Renderers) {
        this.context['cellRenderer'] = renderer.cellRenderer;
        this.context['headerRenderer'] = renderer.headerRenderer;
        this.context['table'] = renderer.table;
    }

    // get column definitions and togglers
    @Input('columns')
    set columns(columnData: Columns) {
        this.columnDefs = columnData.columnDefs.concat([]);
        this.columnTog = columnData.columnTog.concat([]);
    }

    constructor(
        private http: HttpClient,
    ) {
        this.context = { gridRenderer: this };  // access table properties from renderers
        this.frameworkComponents = {    // set renderer components for the table level
            cellRenderer: CellRendererComponent,
            // agColumnHeader: HeaderRenderComponent
        };
    }

    ngOnInit() { }

    // row event emit
    emitRowEvent(event: RowEvent) {
        this.rowEvent.emit(event);
    }

    // header event emit
    emitHeaderEvent(event: HeaderEvent) {
        this.headerEvent.emit(event);
    }

    // loads grid api
    onGridReady(params: GridOptions) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();    // comment this line to get horizontal scroll
    }

    // sidepan hide/show
    toggleSidepan() {
        this.enableSidepan = !this.enableSidepan;
        this.gridApi.sizeColumnsToFit();
    }

    // column hide/show
    toggleColumns(column: Object) {
        this.gridColumnApi.setColumnsVisible([column['field']], column['visible']);
    }

    // retrieve data from backend
    retrieveData() {
        let status = [];

        if (!this.config.remoteUrl) return;
        const url = GetApiurl(this.config.remoteUrl, this.config.remoteParams);
        if (this.subscriptions['dataFetch']) { this.subscriptions['dataFetch'].unsubscribe(); }

        this.subscriptions['dataFetch'] = this.http.get<Object[]>(url)
            .pipe(catchError(E => { console.log(E); return E }))
            .subscribe(
                (data) => {
                    this.rowData = data['data'];
                    this.gridApi.sizeColumnsToFit();
                    // this._page.totalElements = data['totalRecords'];// :ToDo: data.length;

                    delete this.subscriptions['dataFetch'];
                    // setTimeout(() => { this.loading = false; }, 1500);
                    // this.loading = false;
                },
                error => {
                    this.rowData = [];
                    delete this.subscriptions['dataFetch'];
                    this.gridApi.sizeColumnsToFit();
                    // this.loading = false;
                }
            );
    }

    // search
    searchEvent(event: any) {
        this.rowData = event['data'];
        this.gridApi.sizeColumnsToFit();
    }

    ngOnDestroy() {
        // Unsubscribe on service scope destroy
        Object.entries(this.subscriptions).forEach(
            ([name, subscription]) => subscription.unsubscribe()
        );
    }

}
