import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';

import { ErrorRoutingModule } from './error.routing';
import { ErrorComponent } from './error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

@NgModule({
    declarations:
        [
            NotFoundComponent,
            ErrorComponent,
            AccessDeniedComponent
        ],
    imports: [
        ErrorRoutingModule,
        SharedModule
    ],
    exports: [
    ]
})
export class ErrorModule { }

