import { AuthRedirectorService } from './services/authservice/authguardservice';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services';
import { ErrorModule} from './error/error.module'

const routes: Routes = [
  {
    path: 'app',
    loadChildren: './features/features.module#FeaturesModule',
    canActivate: [AuthGuardService],
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [AuthRedirectorService]
  },
  {
    path: 'error',
    loadChildren: './error/error.module#ErrorModule'
  },  
  {
    path: '**',
    redirectTo: 'error'
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
