export const access: Object = {
    // JOB_SCHEDULER_FULL_ACCESS : ['event_scheduler.view_event', 'event_scheduler.list_event'],
    // CUSTOMER_VIEW_FULL_ACCESS : ['customer.view_customer', 'customer.list_customer'],
    // PLANTS_VIEW_FULL_ACCESS : ['customer.view_plants', 'customer.list_plants'],
    // EQUIPMENT_VIEW_FULL_ACCESS : ['equipment.view_equipment', 'equipment.list_equipment'],
    // USER_CHANGE_FULL_ACCESS:['users.change_tribuser'],
    // COMPONENT_FULL_ACCESS:['components.view_components', 'components.list_components'],
    // ORIENTATION_FULL_ACCESS:['components.view_orientation', 'components.list_orientation'],
    // RULES_FULL_ACCESS:['rules.view_rules', 'rules.list_rules'],
    // ANALYSIS_TECHNIQUE_FULL_ACCESS:['components.view_analysistechnique', 'components.list_analysistechnique'],
    // NOTIFICATION_GROUP_FULL_ACCESS: ['notifications.list_notificationgroups', 'notifications.view_notificationgroups'],
    // MASTER_DATA_ACCESS: ['components.view_components', 'components.list_components','components.view_orientation', 'components.list_orientation',
    //                     'rules.view_rules', 'rules.list_rules','components.view_analysistechnique', 'components.list_analysistechnique',
    //                     'notifications.list_notificationgroups', 'notifications.view_notificationgroups' ]
}