import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { VendorService } from '../vendor.service';
import { throwError } from 'rxjs';

// Constants
import { GetApiurl } from '../../parameters';

// Classes
import { Roles } from '../../classes';

@Injectable()
export class RolesService {

    constructor(
        private http: HttpClient,
        private vendor: VendorService
    ) { }
    createRole(role: Roles) {
        const url = GetApiurl(`roles/`);
        return this.http
            .post(url, JSON.stringify(role))
            .pipe(map(Response => Response), catchError(this.vendor.handleError));
    }
    updateRole(role: Roles) {
        const url = GetApiurl(`roles/${role.id}/`);

        return this.http
            .patch(url, JSON.stringify(role))
            .pipe(map(Response => Response),
            catchError(err => this.vendor.handleError(err)));
    }

    getRoles() {
        const url = GetApiurl(`roles/`);

        return this.http
            .get(url)
            .pipe(map(Response => Response),
             catchError(err => this.vendor.handleError(err)));
    }

    // Get role detail
    getRole(id: number) {
        const url = GetApiurl(`roles/${id}`);

        return this.http
            .get(url)
            .pipe(map(Response => Response),
            catchError(err => this.vendor.handleError(err)));
    }

}
