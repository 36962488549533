import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from 'src/@fuse/components/navigation/navigation.service';
import { GlobalService } from 'src/app/services';
import { element } from 'protractor';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    subscriptions: object = {};
    user_details: any;
    role_name: string;
    permissions: any;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private gs: GlobalService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.subscriptions['user_details'] = this.gs.user$.subscribe(userInfo => {
            this.user_details = userInfo;
            if (this.user_details) {
                this.permissions = this.user_details.permissions;
                if (this.user_details['is_superuser']) {
                    this.role_name = 'A'
                }
                else {
                    this.role_name = this.user_details['user_type']
                }
            }
        });
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                let access = false;
                let k = 0;
                this.navigation[0].children.forEach((item, index) => {
                    if (this.user_details.user_type == 'V' || this.user_details.user_type == 'VG' ) {
                        if (item.title == "Purchase Order") {
                            this.navigation[0].children[index].show = false;
                        }
                        else {
                            this.navigation[0].children[index].show = true;
                        }
                    } else if (this.user_details.user_type === 'CSE') {
                        this.navigation[0].children[index].show = true;
                        // this.permissions.push('vendor.view_vendorpo')
                    } else {
                        this.navigation[0].children[index].show = true;
                    }
                    if(this.user_details.user_type == 'CSE' || this.user_details.user_type == 'VSE' || this.user_details.user_type == 'VSE'){
                        this.navigation[0].children[index].show = true;
                    }
                    if (item.type === 'collapsable') {
                        for (var j = 0; j < item.children.length; j++) {
                            let a = this.permissions.filter(i => i == item.children[j].access[0])
                            if (a.length > 0) {
                                k++;
                            }
                        }
                        if (k == 0) {
                            this.navigation[0].children[index].show = false;
                            // this.navigation[0].children.splice(index, 1);
                        }
                        else if (k > 0) {
                            this.navigation[0].children[index].show = true;
                        }
                    }
                });
                
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }
    add(arr) {
        // const { length } = arr;
        // const id = length + 1;
        const found = arr.some(el => el.title === 'Purchase Order');
        if (!found) {
            arr.push({
                id: 'admin',
                title: 'Purchase Order',
                type: 'item',
                imageUrl: 'assets/images/side-menu/PO.png',
                access: ['vendor.add_vendorpo'],
                url: '/app/po/list'
            });
            return arr;
        }
    }
}
