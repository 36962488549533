import { Injectable } from '@angular/core';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap } from 'rxjs/operators';
import {Observable, BehaviorSubject } from 'rxjs';
import { JwtInterceptor } from '../jwt-interceptor.service';
@Injectable()
export class CustomerService extends JwtInterceptor {


    constructor(private http: HttpClient) {
        super();
    }
    private commentdetails = new BehaviorSubject(null);
    $commentdetails = this.commentdetails.asObservable();

    private callStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public callStatus$: Observable<any> = this.callStatus.asObservable();


   public publishCallStatus(content) {
        this.callStatus.next(content);
    }

    // Method to get all customers have not subscribed for membership
    public getAllCustomer() {
        return this.http.get(GetApiurl('customer-all/'),
            {
                params: {
                    'membership': '1'
                }
            }
        )
            .pipe(tap(res => {
                return res;
            }),
                catchError(this.handleError)
            )
    }

    // Method to Register customer
    public registerCustomer(data) {
        const url: string = GetApiurl('customer/')

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    // Method to save phone number
    public registerNumber(data) {
        const url: string = GetApiurl('customer/register-user/')

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    // Method for verifying the Otp
    public verifyOtp(data) {
        const url: string = GetApiurl(`customer/verify-customer-otp/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    // Otp to resend method
    public resendOtp(phone) {
        const url: string = GetApiurl(`customer/resend-otp/${phone}/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    // To set password for the registered customer
    public setPassword(value) {
        const url: string = GetApiurl('customer/set-password/')
        return this.http.post(url, value)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    // customer dependent add 
    public addCustomerDependent(value, documents, medical_documents) {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let arr: any;
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            for (var file in documents) {
                if (documents[file]['file'] != undefined) {
                    formData.append(documents[file]['type'], documents[file]['file'], documents[file]['file'].name);
                }
            }
            if (medical_documents) {
                medical_documents.forEach(file => {
                    if (file['doc_url']) {
                        if (file['doc_url'].name) {
                            formData.append("Medical[]", file['doc_url'], (file['treatment_name'] + '+' + file['doc_url'].name));
                        }
                    }
                });
            }
            formData.append('form_data', JSON.stringify(value));
            xhr.open('POST', GetApiurl('dependents/'), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }
    public updateCustomerDependents(value, id) {
        const url = GetApiurl(`dependents/${id}/`)
        return this.http.put(url, value)
            .pipe(tap(res => {
                return res
            }));
    }
    public deleteDependentFile(id) {
        const url = GetApiurl(`dependents/${id}/`)
        return this.http.delete(url)
            .pipe(tap(res => {
                return res
            }));
    }
    public deleteDependentMedicalFile(id) {
        const url = GetApiurl(`dependents/delete-medical-file/`)
        return this.http.get(url, {
            params: {
                'dep_id': id
            }
        })
            .pipe(tap(res => {
                return res
            }));
    }

    public uploadDocumentsForEditCustomer(data, documents, medical_documents, id) {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if (documents) {
                for (var file in documents) {
                    if (documents[file]['doc_url'] != undefined) {
                        if (documents[file]['doc_url'].name) {
                            formData.append(documents[file]['doc_name'], documents[file]['doc_url'], documents[file]['doc_url'].name);
                        }
                        else {
                            formData.append(documents[file]['doc_name'], documents[file]['doc_url']);
                        }
                    }
                }
            }
            if (medical_documents) {
                medical_documents.forEach(file => {
                    if (file['doc_url']) {
                        if (file['doc_url'].name) {
                            formData.append("Medical[]", file['doc_url'], (file['treatment_name'] + '+' + file['doc_url'].name));
                        }
                    }
                });
            }
            formData.append('form_data', JSON.stringify(data));
            xhr.open('PUT', GetApiurl(`dependents/${id}/`), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }


    public AddDocumentsForDependent(data, documents, medical_documents) {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let tempformData: FormData = new FormData();
            let arr: any;
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if (documents) {
                for (var file in documents) {
                    if (documents[file]['doc_url'] != undefined) {
                        if (documents[file]['doc_url'].name) {
                            formData.append(documents[file]['doc_name'], documents[file]['doc_url'], documents[file]['doc_url'].name);
                        }
                        else {
                            formData.append(documents[file]['doc_name'], documents[file]['doc_url']);
                        }
                    }
                }
            }
            var file_data: Array<any> = [];
            if (medical_documents) {
                medical_documents.forEach(file => {
                    if (file['doc_url']) {
                        if (file['doc_url'].name) {
                            formData.append("Medical[]", file['doc_url'], (file['treatment_name'] + '+' + file['doc_url'].name));
                        }
                    }
                });
            }
            formData.append('form_data', JSON.stringify(data));
            xhr.open('POST', GetApiurl(`dependents/`), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }
    // Upload document for dependents
    public uploadDocuments(documents, id) {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let arr: any;
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            for (var file in documents) {
                if (documents[file]['file'] != undefined) {
                    formData.append(documents[file]['type'], documents[file]['file'], documents[file]['file'].name);
                }
            }
            xhr.open('POST', GetApiurl(`dependents/add-dependent-docs/${id}/`), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }
    // upload medical documents
    public uploadMedicalDocuments(documents: File, value, id) {
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let arr: any;
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if (documents != undefined) {
                formData.append('docs', documents);
            }
            formData.append('form_value', JSON.stringify(value));
            xhr.open('POST', GetApiurl(`dependents/add-medical-docs/${id}/`), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }


    public getDependentforCustomer(id) {
        const url = GetApiurl(`dependents/${id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }))
    }
    public getDependentDocs(dependent_id) {
        const url = GetApiurl(`dependent-docs/${dependent_id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }))
    }
    public getDependentMedicalDocs(dependent_id) {
        const url = GetApiurl(`medical-docs/${dependent_id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }))
    }

    public checkDependencies(customer_id, type) {
        const url = GetApiurl('dependents/check-dependent-dependencies/')
        return this.http.get(url, {
            params: {
                'customer_id': customer_id,
                'type': type
            }
        })
            .pipe(tap(Response => {
                return Response
            }))
    }
    // Get customer details based in id
    public getCustomer(id) {
        const url = GetApiurl(`customer/${id}/`)
        return this.http.get(url)
            .toPromise()
            .then(res => {
                return res
            })
    }

    // Update details of customer in superadmin screen
    public updateCustomer(value, id) {
        const url = GetApiurl(`customer/${id}/`)
        return this.http.put(url, value)
            .toPromise()
            .then(res => {
                return res
            })
    }
    public viewSnapshot(id) {
        const url = GetApiurl(`customer/${id}/view-snapshot`)
        return this.http.get(url)
            .toPromise()
            .then(res => {
                return res
            })
    }
    public addComments(id, value, rm_id, call_status, groupId?: any) {
        const url = GetApiurl(`customer/${id}/add-comments/`)
        return this.http.post(url, { 'data': value, 'rm_id': rm_id, 'call_status': call_status ,'groupId':groupId})
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public setComment(value) {
        this.commentdetails.next(value);
    }
    public getComments(id) {
        const url = GetApiurl(`customer/${id}/get-comments/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getGroup(id) {
        const url = GetApiurl(`customer-group/${id}/get-group/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public addGroup(id, name) {
        const url = GetApiurl(`customer-group/${id}/add-group/`)
        return this.http.post(url, { 'data': name })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public addFeedbackQuestion(id, question, user_id) {
        const url = GetApiurl(`customer-group/${id}/add-feedback-question/`)
        return this.http.post(url, { 'data': question, 'user_id': user_id })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    
    public addFeedbackAnswwer(id, comments, ratings){
        const url = GetApiurl(`customer-group/${id}/add-feedback-answer/`)
        return this.http.post(url, { 'data': comments, 'ratings': ratings })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public closeFeedback(id) {
        const url = GetApiurl(`customer-group/${id}/close-feedback/`)
        return this.http.post(url, id)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public closePopup(id, banner_type) {
        const url = GetApiurl(`customer-group/${id}/close-popup/`)
        return this.http.post(url, { 'banner_type': banner_type })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public viewFeedback(id, groupid, month) {
        const url = GetApiurl(`customer-group/${id}/view-feedback/${groupid}, ${month}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getCustomerCallCancel(id, rm_id, call_status, group_id) {
        const url = GetApiurl(`customer/${id}/add-call-status/`)
        return this.http.post(url, { 'rm_id': rm_id, 'call_status': call_status, 'group_id': group_id })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getCustomerName(groupid) {
        const url = GetApiurl(`customer/${groupid}/get-customer-name/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getCustomerCallStatus(groupid, month, end_date, search_text) {
        const url = GetApiurl(`customer/get-customer-call-status/${groupid}/`)
        return this.http.get(url, {
            params: {
                'month': month,
                'end_date': end_date,
                'search_text': search_text
            }
        })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    
    // public getSearchRemaiderStatus(searchText:string){
    //     const url = GetApiurl(`customer/get-search/${searchText}`)
    //     return this.http.get(url)
    //         .pipe(
    //             tap(res => {
    //                 return res
    //             }),
    //             catchError(this.handleError)
    //         )
    // }

    public viewFeedbackSearch(id, groupid, start_date, end_date, status) {
        const url = GetApiurl(`customer-group/${id}/view-feedback-search/${groupid}, ${start_date}, ${end_date}, ${status}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public viewFeedbackMonthSearch(id, groupid, month, end_date) {
        const url = GetApiurl(`customer-group/${id}/view-feedback-month-search/${groupid}, ${month}, ${end_date}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getGroupDetails(id) {
        const url = GetApiurl(`customer-group/${id}/get-group-details/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public viewUsers(id,group_id,rm_id) {
        const url = GetApiurl(`customer-group/${id}/view-users/${group_id},${rm_id}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    
    public customerFeedbackStatus(id) {
        const url = GetApiurl(`customer-group/${id}/customer-feedback-status/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getFeedbackQuestion(id) {
        const url = GetApiurl(`customer-group/${id}/get-feedback-question/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public customerBannerStatus(id) {
        const url = GetApiurl(`customer-group/${id}/customer-banner-status/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getVendorList() {
        const url = GetApiurl('ticket-master/get-vendor-list/');
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getItemFromSelectedVendor(id, value) {
        const url: string = GetApiurl(`vendor/${id}/get-items-for-vendor/`, value)
        return this.http.post(url, value)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getAllService() {
        const url: string = GetApiurl('services/get-all-service/');
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getSubServiceForService(serviceId) {
        const url: string = GetApiurl(`sub-service/${serviceId}/get-sub-service/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getServiceTypeForsubService(subServiceId, vendor?: any, screenType?: any) {
        const url: string = GetApiurl(`ticket-master/${subServiceId}/get-service-type/?vendor=${vendor}&screenType=${screenType}`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getServicePreference(serviceId) {
        const url: string = GetApiurl(`service-preference/${serviceId}/get-service-preference/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getServiceFrequency(serviceId) {
        const url: string = GetApiurl(`service-frequency/${serviceId}/get-service-frequency/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }
    public getDependentdetails(id) {
        const url = GetApiurl(`dependents/get-dependent/${id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError)
            )
    }
    public addMembership(value, file, filedata) {
        const url = GetApiurl('membership/')
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('file', file, filedata);
            }
            for (var key in value) {
                // check if the property/key is defined in the object itself, not in parent
                if (value.hasOwnProperty(key)) {
                    formData.append(key, value[key]);
                }
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });

        // return this.http.post(url, value)
        //     .pipe(tap(Response => {
        //         return Response
        //     }),
        //         catchError(this.handleError)
        //     )
    }
    public getMembership() {
        const url = GetApiurl(`membership/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError)
            )
    }
    public getSpecificMembership(id) {
        const url = GetApiurl(`membership/${id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError)
            )
    }
    public updateMembership(id, value, filedata) {
        const url = GetApiurl(`membership/${id}/`)
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('file', filedata, filedata.name);
            }
            for (var key in value) {
                // check if the property/key is defined in the object itself, not in parent
                if (value.hasOwnProperty(key)) {
                    formData.append(key, value[key]);
                }
            }
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });

        // return this.http.put(url, value)
        //     .pipe(tap(Response => {
        //         return Response
        //     }),
        //         catchError(this.handleError)
        //     )
    }
    public checkMembership(value) {
        const url = GetApiurl('membership/check-membership-exists/')
        return this.http.get(url, {
            params: {
                'membership': value
            }
        })
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError)
            )
    }
    // Get dependents(self included) for particular customer
    // public getCustomerDependent(id) {
    //     const url = GetApiurl(`dependents/get-dependent-for-customer/${id}/`)
    //     return this.http.get(url)
    //         .pipe(tap(Response => {
    //             return Response;
    //         }))
    // }

    //get self customer
    public getSelfCustomer(id) {
        const url = GetApiurl(`dependents/get-self-customer/${id}/`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response;
            }))
    }

    // Make payment for membership plan and wallet recharge
    public paymentPost(plan_details) {
        const url = GetApiurl(`customer-membership/make-payment/`)
        return this.http.post(url, plan_details)
            .pipe(tap(Response => {
                return Response;
            }),
                catchError(this.handleError))

    }
    public getCityState(pincode) {
        const url = GetApiurl(`customer/get-city-state/`)
        return this.http.get(url, {
            'params': {
                'pincode': pincode
            }
        })

    }
    public customerProfile(customer_id, filedata: File) {
        const url = GetApiurl(`customer/update-profile/`)
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('profile', filedata, filedata.name);
            }
            formData.append('customer_id', customer_id)
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
    }

    public customerSearch(value) {
        const url = GetApiurl(`membership/search-customers?customer=${value}`)
        return this.http.get(url)
            .pipe(tap(Response => {
                return Response;
            }))
    }

    public getUsers(id, name) {
        const url: string = GetApiurl(`customer-group/${id}/get-users/`);
        return this.http.get(url, {
            params: {
                'city_name': name
            }
        })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public editUsers(id, name) {
        const url: string = GetApiurl(`customer-group/${id}/edit-users/`);
        return this.http.get(url, {
            params: {
                'city_name': name
            }
        })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getCities(id, group_id) {
        const url: string = GetApiurl(`customer-group/${id}/get-cities/`);
        return this.http.get(url, {
            params: {
                'group_id': group_id
            }
        })
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public addCustomers(customer_details, grp_id, rm_id) {
        const url = GetApiurl(`customer-group/add-customers/`)
        return this.http.post(url, { 'data': customer_details, 'id': grp_id, 'rm_id': rm_id })
            .pipe(tap(Response => {
                return Response;
            }),
                catchError(this.handleError))

    }

    public removeCustomers(customer_details, grp_id, rm_id) {
        const url = GetApiurl(`customer-group/remove-customers/`)
        return this.http.post(url, { 'data': customer_details, 'id': grp_id, 'rm_id': rm_id })
            .pipe(tap(Response => {
                return Response;
            }),
                catchError(this.handleError))

    }
}
