import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Input } from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { startWith, map } from 'rxjs/operators';
import { SearchList, SearchUrl } from './slim-search';
import { GetApiurl } from 'src/app/parameters';

@Component({
    selector: 'slim-search',
    templateUrl: './slim-search.component.html',
    styleUrls: ['./slim-search.component.scss']
})
export class SlimSearchComponent implements OnInit {
    removable: boolean = true;
    loading: boolean = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    searchControl: FormControl = new FormControl();

    filteredOptions: Observable<string[]>;
    selectedOptions: string[] = [];

    allOptions: Array<any> = [];
    tempOptions: Array<any> = [];

    isKey: boolean = true;
    searchQuery: Object = {};

    searchResultUrl: string = '';
    searchValuesUrl: string = '';

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('autoComplete', { static: false }) matAutocomplete: MatAutocomplete;
    @ViewChild('trigger', { static: false }) trigger: MatAutocompleteTrigger;

    @Output('searchResult') searchResult: EventEmitter<any> = new EventEmitter();

    @Input('searchList')
    set setOptions(options: SearchList[]) {
        this.allOptions = options;
        this.tempOptions = options;
    }

    @Input('searchUrl')
    set setUrl(url: SearchUrl) {
        this.searchResultUrl = url.searchResultUrl;
        this.searchValuesUrl = url.searchValuesUrl;
    }

    constructor(private http: HttpClient, private fb: FormBuilder) {
        this.loading = false;
        this.setFilter();
    }

    ngOnInit() { }

    setFilter() {
        this.filteredOptions = this.searchControl.valueChanges.pipe(
            startWith(null),
            map((options: any) => options ? this._filter(options) : this.allOptions)
        );
    }

    private _filter(value: any): string[] {
        let filterValue: any;
        if (typeof value == 'string') {
            filterValue = value.toLowerCase();
        }
        else {
            filterValue = (typeof value == 'object' ? value['searchKey'] : value).toLowerCase();
        }

        let filtered = this.allOptions.filter(
            option => {
                if (this.isKey) {
                    return option['searchKey'].toLowerCase().indexOf(filterValue) === 0;
                }
                else {
                    return option.toLowerCase().indexOf(filterValue) === 0;
                }
            }
        ).map(option => option);
        return filtered;
    }

    remove(selectedOption: any): void {
        const selectedOptionsIndex = this.selectedOptions.indexOf(selectedOption);
        let selectedOptionKey = selectedOption.split(':')[0];
        this.tempOptions.forEach((element, elementIndex) => {
            if (element['searchKey'] == selectedOptionKey) {
                this.tempOptions[elementIndex]['display'] = true;
                if ('checked' in element) {
                    this.tempOptions[elementIndex]['checked'] = false;
                }
                if (this.selectedOptions[this.selectedOptions.length - 1] == selectedOption) {
                    this.allOptions = this.tempOptions;
                    this.isKey = true;
                    this.loading = false;
                }
                else if (this.selectedOptions[this.selectedOptions.length - 1] != selectedOption) {
                    this.isKey = false;
                }
                delete this.searchQuery[element.property];
                this.selectedOptions.splice(selectedOptionsIndex, 1);
            }
        });
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();
        this.trigger.closePanel();
        setTimeout(() => {
            this.trigger.openPanel();
        }, 0);
        this.searchInput.nativeElement.focus();
    }

    checked(event, triggger: MatAutocompleteTrigger, value: any, isCheckbox: boolean) {
        let index = this.tempOptions.indexOf(value);

        if (this.tempOptions[index]['checked']) {
            let idx = this.selectedOptions.indexOf(value['searchKey']);
            this.selectedOptions.splice(idx, 1);
            delete this.searchQuery[value['searchKey']];
        }
        else {
            this.selectedOptions.push(value['searchKey']);
            this.searchQuery[value.property] = true;
        }
        if (!isCheckbox) {
            this.tempOptions[index]['checked'] = !this.tempOptions[index]['checked'];
        }
        this.allOptions = this.tempOptions;
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();
        triggger.closePanel();
        event.stopPropagation();
        triggger.openPanel();
        this.searchInput.nativeElement.focus();
    }

    emitSearch() {
        if (this.isKey && this.selectedOptions.length) {
            this.loading = true;
            this.http.post(GetApiurl(`${this.searchResultUrl}/`), JSON.stringify({ searchQuery: this.searchQuery })).subscribe((response: string[]) => {
                    this.searchResult.emit(response);
                    this.loading = false;
                },
                    (error: any) => {
                        this.loading = false;
                    }
                );
        }
    }

    clearSearch(event: any, triggger: MatAutocompleteTrigger) {
        this.selectedOptions = [];
        this.tempOptions.forEach((element, index) => {
            this.tempOptions[index].display = true;
            if ('checked' in this.tempOptions[index])
                this.tempOptions[index].checked = false;
        });
        this.allOptions = this.tempOptions;
        this.isKey = true;
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();
        this.searchQuery = {};
        this.searchInput.nativeElement.focus();
        this.trigger.closePanel();
        event.stopPropagation();
        triggger.openPanel();
    }

    bkspcRemove(event: any) {
        this.loading = false;
        if (this.selectedOptions.length && event.target.value == '') {
            let removed = this.selectedOptions.pop().split(':')[0];
            this.tempOptions.forEach(
                (el, idx) => {
                    if (el['searchKey'] == removed) {
                        if ('checked' in el) {
                            this.tempOptions[idx].checked = false;
                        }
                        this.tempOptions[idx].display = true;
                        this.allOptions = this.tempOptions;
                        this.setFilter();
                        this.isKey = true;
                        delete this.searchQuery[el.property];
                        this.trigger.closePanel();
                        setTimeout(() => {
                            this.trigger.openPanel();
                        }, 0);
                    }
                }
            );
        }
    }

    select(event: MatAutocompleteSelectedEvent): void {
        let viewValue = event.option.viewValue;
        let value = event.option.value;

        if (this.isKey) {
            this.loading = true;
            let index = this.tempOptions.indexOf(value);
            if (!('checked' in value)) {
                if (value['searchValues'].length) {
                    this.allOptions = value['searchValues'];
                }
                else {
                    this.allOptions = [];
                    this.removable = false;
                    this.http.get(GetApiurl(`${this.searchValuesUrl}`, { searchKey: value['property'] }),
                        ).subscribe((response: string[]) => {
                            this.allOptions = response.concat([]);
                            this.setFilter();
                            this.trigger.closePanel();
                            setTimeout(() => {
                                this.trigger.openPanel();
                            }, 0);
                            this.searchInput.nativeElement.value = '';
                            this.searchControl.reset();
                            this.removable = true;
                            this.loading = false;
                        }
                        );
                }
                this.selectedOptions.push(viewValue + ':');
                this.tempOptions[index].display = false;
            }
            else {
                if (this.tempOptions[index]['checked']) {
                    let idx = this.selectedOptions.indexOf(viewValue);
                    this.selectedOptions.splice(idx, 1);
                    delete this.searchQuery[this.tempOptions[index].property];
                }
                else {
                    this.selectedOptions.push(viewValue);
                    this.searchQuery[value.property] = true;
                }
                this.tempOptions[index].checked = !this.tempOptions[index].checked;
                this.allOptions = this.tempOptions;
                this.isKey = !this.isKey;
            }
        }
        else {
            let index = this.selectedOptions.length - 1;
            let keyValue = this.selectedOptions[index].split(':')[0];
            this.tempOptions.forEach(
                (el, idx) => {
                    if (el['searchKey'] == keyValue) {
                        this.searchQuery[el.property] = viewValue;
                        this.selectedOptions[index] = this.selectedOptions[index] + ' ' + viewValue;
                        this.allOptions = this.tempOptions;
                    }
                }
            );
            this.loading = false;
        }
        this.isKey = !this.isKey;
        this.searchInput.nativeElement.value = '';
        this.searchControl.reset();

        this.trigger.closePanel();
        setTimeout(() => {
            this.trigger.openPanel();
        }, 0);
    }

    hasKey(key: any, filtered: Object): boolean {
        return key in filtered;
    }

}
