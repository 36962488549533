import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationService {

    private statusUpdate = new BehaviorSubject<any>(null);
    status$ = this.statusUpdate.asObservable();

    constructor(private http: HttpClient) { }
    // ticket-master/

    public notificationUpdate(msg) {
        this.statusUpdate.next(msg);
    }

    public getNotifications() {
        const url: string = GetApiurl('notifications/get-pop-up-list/');

        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public changeNotificationStatus(id) {
        const url = GetApiurl(`notifications/${id}/`);
        return this.http.patch(url, id)
        .pipe(
            tap(res => {
                return res;
            }),
            catchError(err => err)
        );
    }

}
