/** --- Default angular module --- */
import {
  Component,
  ViewChild,
  AfterViewInit,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

/** --- Backend api, Excel type valid --- */
import { GetApiurl, GetValidxcelTypes } from 'src/app/parameters';

/** --- Table interface --- */
import { tableConfig, Column } from './interfaces';

/** --- Checkbox selection --- */
import { SelectionModel } from '@angular/cdk/collections';

/** --- Toaster --- */
import { ToastrService } from 'ngx-toastr';

/** --- Http service --- */
import { HttpClient } from '@angular/common/http';

/** --- Mat table --- */
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

/** --- JQuery --- */
declare var $: any;

@Component({
  selector: 'app-custom-datatable',
  templateUrl: './custom-datatable.component.html',
  styleUrls: ['./custom-datatable.component.scss'],
})
export class CustomDatatableComponent implements OnInit {

  /** --- Event emitter --- */
  @Output() downloadCsv: EventEmitter<any> = new EventEmitter();  // Action click emit
  @Output() actionClickEvent: EventEmitter<any> = new EventEmitter();  // Action click emit
  @Output() tableButton: EventEmitter<any> = new EventEmitter();  // Create button
  @Output() totalDataEmit: EventEmitter<any> = new EventEmitter();  // Backend all data emit
  @Output() valueClickEmit: EventEmitter<any> = new EventEmitter(); // For specific value click in column
  @Output() openCloseContainer: EventEmitter<any> =  new EventEmitter(); //For Get Price Popup


  /** --- Variables --- */
  displayedColumns: Array<any> = [];
  Columns: Array<Column> = [];
  backend: HttpDatabase | null;
  data: MatTableDataSource<any>;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  Config: tableConfig;
  selection = new SelectionModel<CustomDatatableComponent>(true, []);
  row: Array<any> = [];
  rowCall: Array<any> = [];
  searchText: any = "";
  emptyRecord: number = -1;

  /** --- Default pagination --- */
  pagination_array: Array<string> = ['10', '25', '50', '100'];

  /** --- View child --- */
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private _httpClient: HttpClient,
    private ts: ToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  /** --- Table config set --- */
  @Input('tableConfig')
  set tableConfig(value: tableConfig) {
    this.Config = value;
    if ('pagination_array' in value) {
      this.pagination_array = value['pagination_array']
    }
  }

  /** --- Table columns set --- */
  @Input('columns')
  set columns(value: Array<Column>) {
    this.Columns = value || [];
    this.Columns.forEach(ele => {
      this.displayedColumns.push(ele['prop']);
    });
  }

  @Input() hidePopupForm : boolean;
  @Input() userType : any;

  /** --- First time load --- */
  ngAfterViewInit() {
    this.fetchRecords();
  }

  /** --- Get price popup */

  onOpenClose() {
    this.openCloseContainer.emit('true');
  }

  /** --- Refresh table --- */
  refreshTable() {
    this.selection.clear();
    this.fetchRecords();
  }

  /** --- Getting records --- */
  fetchRecords() {
    this.emptyRecord = -1;
    this.backend = new HttpDatabase(this._httpClient);
    this.sort.sortChange.subscribe(() => {
      // this.paginator.pageIndex = 0
      // this.data.paginator = this.paginator;
    });
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          if ('remoteParams' in this.Config) {
            return this.backend!.getRepoIssues(
              this.Config['remoteUrl'], this.sort.active, this.sort.direction,
              this.paginator.pageIndex, this.paginator.pageSize,
              this.searchText, this.Config['remoteParams']);
          } else {
            return this.backend!.getRepoIssues(
              this.Config['remoteUrl'], this.sort.active, this.sort.direction,
              this.paginator.pageIndex, this.paginator.pageSize,
              this.searchText);
          }
        }),
        map(data => {
          if (data === 'Access Denied') {
            this.router.navigate(['/error/access-denied']);
          }
          else {
            this.isLoadingResults = false;
            this.isRateLimitReached = false;
            this.resultsLength = data.totalRecords;
            this.emptyRecord = data.totalRecords;
            this.row = data.records;
            this.rowCall = data.customername;
            console.log()
            this.totalDataEmit.emit(this.row);
              return data.records;
          }
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.data = new MatTableDataSource(data));
  }

  /** --- Filter apply --- */
  applyFilter(filterValue: string) {
    this.searchText = filterValue;
    this.fetchRecords();
    setTimeout(() => this.paginator.firstPage(), 1000);
  }

  /** --- Add button action emit --- */
  addButton() {
    this.tableButton.emit(true)
  }

  /** --- Action button emit --- */
  eventEmit(event) {
    this.actionClickEvent.emit(event);
  }

  /** --- row click emit --- */
  rowEventEmit() {
    this.actionClickEvent.emit(event);
  }

  /** --- value click emit --- */
  valueClickEvent(event) {
    this.valueClickEmit.emit(event);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.row.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.row.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    let data = `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    return data;
  }

  /** --- XLSX file upload common --- */
  public downloadCSV() {
    this.downloadCsv.emit(true)
  }
}

/** --- backend connection --- */
export class HttpDatabase {
  constructor(private _httpClient: HttpClient) {
  }

  getRepoIssues(url: string, sort: string, order: string, page: number, pageSize: number, searchText: string, params?: any): Observable<any> {
    const offset = page * pageSize;
    const end = (page + 1) * pageSize;
    let key = "";
    if (order === "desc") {
      key = `-${sort}`;
    } else if (order === "asc") {
      key = sort;
    }
    let common_params = { "sort": key, "offset": offset, "end": end };
    if (searchText) {
      Object.assign(common_params, { "searchText": searchText });
    }
    if (params) {
      Object.assign(common_params, params);
    }
    let requestUrl = GetApiurl(url, common_params);
    return this._httpClient.get<any>(requestUrl);
  }
}
