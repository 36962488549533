import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetApiurl } from '../../parameters';
import { GlobalService } from './globalservice';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../../classes';
import { Subject, Observable, timer } from 'rxjs';
// import 'rxjs/add/operator/catch';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  user: User;
  token: any;
  userPermissions: Array<string> = [];
  ngUnSubscribe: Subject<void> = new Subject<void>();
  public redirectUrl: string = '';
  // constructor(
  //     private http: HttpClient,
  //     private router: Router, ) {

  // }

  constructor(
    private http: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService,
    private gs: GlobalService
    // private jwthelper: new JwtHelperService(),
    // @Inject(forwardRef(() => GlobalService)) private global)
  ) {
    // this.global.user$.takeUntil(this.ngUnSubscribe).subscribe(u => this.user = u);
    // this.global.userPermissions$.takeUntil(this.ngUnSubscribe).subscribe(ps => this.userPermissions = ps);
  }
  public isAuthenticated() {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      const details = this.jwtHelper.decodeToken(token);
      localStorage.setItem('username', details['username'])
      const isExpired = this.jwtHelper.isTokenExpired(token, details.exp);
      return isExpired;
    } else {
      return false;
    }
  }
  public permitted(roles: Array<string>, userTypes?: Array<string>) {
    // Checking for user type permission
    // if (userTypes && (!(userTypes instanceof Array) || (userTypes.indexOf(this.user.user_type) < 0))) {
    //     return false;
    // }

    if (!(this.user instanceof Object) || !(this.userPermissions instanceof Array)) return false;
    else {
      if (intersect(this.userPermissions, roles).length == roles.length) return true;
      else return false;
    }


    function intersect(a, b) {
      var t;
      if (b.length > a.length) t = b, b = a, a = t; // indexOf to loop over shorter
      return a.filter(e => b.indexOf(e) > -1).filter((e, i, c) => c.indexOf(e) === i);// extra step to remove duplicates
    }
  }

  getUser(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    // const url = GetApiurl('users/fetch-user-detail');
    const url = GetApiurl('api-token-auth/');
    return this.http.post(url, data, {
      headers,
    })
      .toPromise()
      .then((Response: any) => this.handleResponse(Response))
  }
  // Handle response and schedule token refresh
  public handleResponse(response) {
    if (response['status'] == 200) {
      response = response;
      localStorage.setItem('jwt_token', response['data']['token']);

      // this.global.setUser(response['user']);
      const now = new Date().valueOf();
      // const jwthelper = new JwtHelperService();
      const tokenExp = this.jwtHelper.decodeToken(response['data']['token']).exp;
      const exp = new Date(0);
      exp.setUTCSeconds(tokenExp);
      const delay = exp.valueOf() - now;

      const timer$ = timer(delay - 60);

      timer$.subscribe(() => {
        this.refreshToken(response['data']['token']).then(
          Response => Response,
          Error => {
            console.log(Error)
            this.router.navigate(['/auth/login'])
          }
        );
      });
      this.gs.setUser(response['data']['user']);
      response['data']['status'] = 200;
      return response['data'];
    }
    else return response;
  }
  public refreshToken(token: string) {
    console.log("Token expired refreshing : ", new Date());

    const url = GetApiurl('api-token-refresh/');
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const credentials = `{"token":"${token}"}`;

    return this.http
      .post(url, credentials, { headers })
      .toPromise()
      .then(((Response: any) => this.handleResponse(Response)))
      .catch(this.handleError);
  }
  // Handeling error
  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    return Observable.throw(errMsg);
  }
  // Redirecting to respective homepages for the recpective users
  redirectToHome() {
    this.router.navigateByUrl('/app/dashboard');
  }
  sendOTP(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    const url = GetApiurl('otp/send-otp');
    return this.http.get(url, { headers, params: { data } })
      .toPromise()
      .then((Response: any) => Response)
      .catch((err) => {
        console.log(err);
      });
  }
  verifyOTP(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    const url = GetApiurl('otp/verify-user-otp/');
    return this.http.post(url, data, { headers })
      .toPromise()
      .then((Response: any) => this.handleResponse(Response))
      .catch((err) => {
        this.handleError(err)
        console.log(err);
      });
  }

  // logout(token = null) {
  //   const url = GetApiurl(`users/logout/`);
  //   if(!token){
  //     this.http
  //     .delete(url)
  //     .pipe(map(Response => Response), catchError(this.handleError))
  //     .subscribe(
  //       Result => {
  //         this.gs.setUser(null);
  //         localStorage.clear();
  //         this.router.navigate(['auth/login']);
      
  //       }
  //     );
  //   }
  //   else {
  //     let headers: HttpHeaders = new HttpHeaders();
  //     headers = headers.append( 'Authorization', `JWT ${token}`);
  //     this.http
  //     .delete(url, { headers })
  //     .pipe(map(Response => Response), catchError(this.handleError))
  //     .subscribe(
  //       Result => {
          
  //       }
  //     );
  //   }
  // }

  logout() {
    this.gs.setUser(null);
    localStorage.clear();
    this.router.navigate(['auth/login']);
  }

}

