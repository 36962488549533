import { FuseNavigation } from 'src/@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        access: [],
        children: [

            // Superadmin side menu
            {
                id: 'admin',
                title: 'Dashboard',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                imageUrl: 'assets/images/side-menu/dashboard.png',
                url: '/app/dashboard',
                access: ['services.delete_services']
            },
            // {
            //     id: 'admin',
            //     title: 'Call Services',
            //     translate: 'NAV.SAMPLE.TITLE',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/call_services.png',
            //     url: '/app/cs',
            //     access: ['services.add_services']
            // },
            {
                id: 'admin',
                title: 'Service Request',
                type: 'item',
                imageUrl: 'assets/images/side-menu/service_request.png',
                access: ['ticketing_system.view_ticketmaster'],
                // ticketing_system.view_ticketmaster
                url: '/app/tickets/list'
            },
            // {
            //     id: 'admin',
            //     title: 'Unregistered Customers',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/customers.png',
            //     url: '/app/unregistered-customers',
            //     access: ['customer.view_customer']
            // },
            {
                id: 'admin',
                title: 'Customer',
                type: 'item',
                imageUrl: 'assets/images/side-menu/customers.png',
                access: ['customer.view_customer'],
                url: 'app/customer-list'
            },
            // {
            //     id: 'admin',
            //     title: 'Customer Group',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/customers.png',
            //     access: ['customer.view_customergroup'],
            //     show: false,
            //     url: 'app/customer-group'
            // },
            {
                id: 'admin',
                title: 'Customer Tickets',
                type: 'item',
                imageUrl: 'assets/images/side-menu/customer_ticket.png',
                access: ['general_tickets.view_customertickets'],
                url: 'app/tickets/customer-tickets/'
            },
            // {
            //     id: 'admin',
            //     title: 'Offline Membership',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/membership_plan.png',
            //     access: ['membership.add_membership'],
            //     url: '/app/membership-dashboard'
            // },
            {
                id: 'admin',
                title: 'Offline Payments',
                type: 'item',
                imageUrl: 'assets/images/side-menu/payment.png',
                access: ['services.add_services'],
                url: 'app/offline-payments'
            },
            {
                id: 'admin',
                title: 'Vendor',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor.png',
                url: 'app/vendor-list',
                access: ['vendor.view_vendor']
            },
            {
                id: 'admin',
                title: 'Vendor Tickets',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_ticket.png',
                url: '/app/tickets/my-tickets',
                access: ['general_tickets.add_vendortickets']
            },
            // {
            //     id: 'admin',
            //     title: 'Service Master',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/all_services.png',
            //     access: ['services.view_services'],
            //     url: '/app/service-master'
            // },
            {
                id: 'admin',
                title: 'Purchase Order',
                type: 'item',
                imageUrl: 'assets/images/side-menu/PO.png',
                access: ['vendor.view_vendorpo'],
                url: '/app/po/list'
            },
            // {
            //     id: 'admin',
            //     title: 'Reminders',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/reminder.png',
            //     url: '/app/reminder',
            //     access: ['customer.add_customer']
            // },            
            {
                id: 'admin',
                title: 'Customer Invoice',
                type: 'item',
                url: 'app/invoice/customer',
                imageUrl: 'assets/images/side-menu/customer_invoice.png',
                access: ['invoice.view_customerinvoice']
            },
            {
                id: 'admin',
                title: 'Vendor Billing',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_billing.png',
                url: '/app/invoice/vendor',
                access: ['invoice.view_vendorinvoice']
            },
            {
                id: 'admin',
                title: 'Vendor Invoice',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_invoice.png',
                url: '/app/invoice/vendor-invoice/list',
                access: ['invoice.view_vendorinvoice']
            },
            // {
            //     id: 'admin',
            //     title: 'Payment',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/payment.png',
            //     url: '/app/pay',
            //     access: ['zone.view_zone']
            // },
            {
                id: 'admin',
                title: 'Reports',
                type: 'collapsable',
                imageUrl: 'assets/images/side-menu/reports.png',
                access: [],
                show: true,
                children: [
                    {
                        id: 'admin',
                        title: 'Vendor',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: `/app/reports/vendor-reports/vendor`
                    },
                    // {
                    //     id: 'admin',
                    //     title: 'Vendor By Zones',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: `/app/reports/vendor/zones`
                    // },
                    // {
                    //     id: 'admin',
                    //     title: 'Number of Jobs by Month',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: `/app/reports/vendor/jobs`
                    // },
                    
                    {
                        id: 'admin',
                        title: 'Tickets',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: '/app/reports/ticketing/tickets'
                    },
                    // {
                    //     id: 'admin',
                    //     title: 'Pending Tickets by Services',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: '/app/reports/ticketing/pending-tickets'
                    // },
                    // {
                    //     id: 'admin',
                    //     title: 'Closed Tickets by days/Month',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: '/app/reports/ticketing/closed-tickets'
                    // },
                    {
                        id: 'admin',
                        title: 'Customer',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: '/app/reports/customer-reports/zone_members'
                    },
                    {
                        id: 'admin',
                        title: 'V-Zone & service',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: `/app/reports/vendor-reports/vendor_service_zones`
                    },
                    // {
                    //     id: 'admin',
                    //     title: 'Member List',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: '/app/reports/customer-reports/active_members'
                    // },
                    {
                        id: 'admin',
                        title: 'Membership status',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: '/app/reports/customer-reports/member_renewal'
                    },
                    {
                        id: 'admin',
                        title: 'Member wallet',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/rep.png',
                        collapse: true,
                        access: ['customer.view_customer'],
                        show: true,
                        url: '/app/reports/customer-reports/member_wallet'
                    },
                    // {
                    //     id: 'admin',
                    //     title: 'Prospective Member list',
                    //     type: 'item',
                    //     imageUrl: '',
                    //     collapse: true,
                    //     access: ['customer.view_customer'],
                    //     show: true,
                    //     url: '/app/reports/customer-reports/prospective_members'
                    // }
                ]
            },
            {
                id: 'admin',
                title: 'My Users',
                type: 'item',
                imageUrl: 'assets/images/side-menu/customers.png',
                url: '/app/my-users',
                access: ['users.view_alservuser']
            },
            {
                id: 'admin',
                title: 'Digiserv User',
                type: 'item',
                imageUrl: 'assets/images/side-menu/alser_user.png',
                url: '/app/users',
                access: ['services.add_services']
            },
            {
                id: 'admin',
                title: 'Settings',
                type: 'collapsable',
                imageUrl: 'assets/images/side-menu/setting.png',
                access: [],
                show: true,
                children: [
                    {
                        id: 'admin',
                        title: 'Hsn Master',
                        type: 'item',
                        // imageUrl:'',
                        imageUrl: 'assets/images/side-menu/hsn.png',
                        collapse: true,
                        access: ['services.add_services'],
                        show: true,
                        url: '/app/hsn'
                    },
                    {
                        id: 'admin',
                        title: 'Zone Master',
                        type: 'item',
                        // imageUrl:'',
                        imageUrl: 'assets/images/side-menu/zone.png',
                        collapse: true,
                        url: 'app/zone-list',
                        show: true,
                        access: ['zone.view_zone']
                    },
                    {
                        id: 'admin',
                        title: 'Digiserv Profile',
                        type: 'item',
                        // imageUrl:'',
                        imageUrl: 'assets/images/side-menu/alserv_profile.png',
                        collapse: true,
                        show: true,
                        url: '/app/alserv-profile',
                        access: ['services.add_services']
                    },
                    {
                        id: 'admin',
                        title: 'Service Master',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/service_master.png',
                        collapse: true,
                        show: true,
                        access: ['services.view_services'],
                        url: '/app/service-master'
                    },
                    {
                        id: 'admin',
                        title: 'Membership Plan',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/membership_plan.png',
                        collapse: true,
                        show: true,
                        access: ['membership.view_membership'],
                        url: 'app/membership-list'
                    },
                    {
                        id: 'admin',
                        title: 'Service Pricing',
                        type: 'item',
                        imageUrl: 'assets/images/side-menu/service_pricing.png',
                        collapse: true,
                        show: true,
                        url: 'app/service-pricing',
                        access: ['services.add_serviceitems']
                    },
                ]
            },
            {
                id: 'admin',
                title: 'Popup Banners',
                type: 'item',
                imageUrl: 'assets/images/side-menu/banner.png',
                url: '/app/popup-banner',
                access: ['common.add_alservbanner']
            },
            // Vendor side menu
            {
                id: 'vendor',
                title: 'Dashboard',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                imageUrl: 'assets/images/side-menu/dashboard.png',
                url: '/app/vendor-dashboard',
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'My Services',
                type: 'item',
                imageUrl: 'assets/images/side-menu/myservice.png',
                url: '/app/my-services',
                // access: ['vendor.change_vendor']
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'My Jobs',
                type: 'item',
                imageUrl: 'assets/images/side-menu/customers.png',
                url: '/app/tickets/my-job',
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'Vendor Tickets',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_ticket.png',
                url: '/app/tickets/my-tickets',
                // access: ['general_tickets.view_vendortickets']
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'My Purchase Order',
                type: 'item',
                imageUrl: 'assets/images/side-menu/PO.png',
                url: 'app/po',
                access: ['vendor.add_vendorstaff']

            },            
            {
                id: 'Vendor Staff',
                title: 'Vendor Staff',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_staff.png',
                url: '/app/vendor-staff',
                // access: ['vendor.change_documentdetails']
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'Vendor Billing',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_invoice.png',
                url: '/app/invoice/vendor',
                access: ['vendor.add_vendorstaff']
            },
            {
                id: 'vendor',
                title: 'Vendor Invoice',
                type: 'item',
                imageUrl: 'assets/images/side-menu/vendor_billing.png',
                url: '/app/invoice/vendor-invoice/list',
                // access: ['vendor.change_vendor']
                access: ['vendor.add_vendorstaff']
            },
            // {
            //     id: 'vendor',
            //     title: 'Reports',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/reports.png',
            //     url: '/app/report',
            //     // access: ['vendor.change_vendor']
            //     access: ['vendor.add_vendorstaff']
            // },
            // {
            //     id: 'vendor',
            //     title: 'All Services',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/alserv_staff.png',
            //     url: '/app/vendor-selection',
            //     // access: ['vendor.view_vendor']
            //     access: ['vendor.view_vendor']
            // },
            // {
            //     id: 'vendor',
            //     title: 'Register Service',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/alserv_staff.png',
            //     url: '/app/register-service',
            //     // access: ['vendor.view_vendor']
            //     access: ['vendor.view_vendor']
            // },
            {
                id: 'vendor',
                title: 'Reminders',
                type: 'item',
                imageUrl: 'assets/images/side-menu/reminder.png',
                url: 'app/reminder',
                access: ['vendor.add_vendorstaff']
            },
            // {
            //     id: '',
            //     title: 'Vendor Invoice',
            //     type: 'item',
            //     imageUrl: 'assets/images/side-menu/image_ven.png',
            //     url: '/app/invoice/vendor-invoice/list',
            //     access: ['invoice.view_vendorinvoice']
            // },

            // Customer side menu    
            {
                id: 'Dashboard',
                title: 'Dashboard',
                translate: 'NAV.SAMPLE.TITLE',
                type: 'item',
                imageUrl: 'assets/images/side-menu/dashboard.png',
                url: '/app/customer-dashboard',
                access: ['customer.view_customerdependent']
            },
            {
                id: 'customerservice',
                title: 'Book a service',
                type: 'item',
                url: 'app/service',
                imageUrl: 'assets/images/side-menu/all_services.png',
                access: ['sponsor.add_sponsor']
            },
            {
                id: 'my_bookings',
                title: 'My Bookings',
                type: 'item',
                url: 'app/bookings',
                imageUrl: 'assets/images/side-menu/vendor_ticket.png',
                access: ['customer.view_customerdependent']
            },
            {
                id: 'my_wallet',
                title: 'My Wallet',
                type: 'item',
                url: 'app/wallet',
                imageUrl: 'assets/images/side-menu/payment.png',
                access: ['customer.view_customerdependent']
            },
            {
                id: 'invoice',
                title: 'Invoice',
                type: 'item',
                url: 'app/invoice/customer',
                imageUrl: 'assets/images/side-menu/customer_invoice.png',
                access: ['customer.view_customerdependent']
            },
            // {
            //     id: 'reminders',
            //     title: 'Reminders',
            //     type: 'item',
            //     url: 'app/reminders',
            //     imageUrl: 'assets/images/side-menu/reminder.png',
            //     access: ['customer.view_customerdependent']
            // },
            // {
            //     id: 'reports',
            //     title: 'Reports',
            //     type: 'item',
            //     url: 'app/reports',
            //     imageUrl: 'assets/images/side-menu/reports.png',
            //     access: ['customer.view_customerdependent']
            // },
            {
                id: 'upgradeplan',
                title: 'My plan',
                type: 'item',
                url: 'app/plan',
                imageUrl: 'assets/images/side-menu/my_plan.png',
                access: ['customer.view_customerdependent']
            },
            {
                id: 'relationship_manager',
                title: 'Reach Digiserv',
                type: 'item',
                url: 'app/customer-managers',
                imageUrl: 'assets/images/side-menu/reach_digiserv.png',
                access: ['customer.view_customerdependent']
            },
            {
                id: 'snapshot',
                title: 'Summary',
                type: 'item',
                url: 'app/customer-summary',
                imageUrl: 'assets/images/side-menu/summary.png',
                access: ['customer.view_customerdependent']
            },
            // {
            //     id: '',
            //     title: 'Customer Invoice',
            //     type: 'item',
            //     url: 'app/invoice/customer',
            //     imageUrl: 'assets/images/side-menu/image_cus.png',
            //     access: ['invoice.view_customerinvoice']
            // },
            //Vendor Staff Side menu
            {
                id: 'vendor_staff',
                title: 'My Tickets',
                type: 'item',
                url: 'app/staff-tickets',
                imageUrl: 'assets/images/side-menu/vendor_ticket.png',
                access: ['ticketing_system.view_ticketdetail']
            },
        ]
    }
];
