import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';

@Injectable()
export class CustomerWalletService {

    private transaction = new BehaviorSubject<any>(null);
    transaction_details$ = this.transaction.asObservable();

    constructor(private http: HttpClient) { }

    public handleError(error: Response | any) {
        let errMsg: string;
        let details;
        if (error instanceof Response) {
            const body = error.json() || "";
            const err = body["error"] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
            details = { detail: body, status: err.status };
        } else {
            errMsg = error.message ? error.message : error.toString();
            details = error.error;
        }
        return throwError(details);
    }

    public TransactionDetails(data) {
        this.transaction.next(data)
    }

    public createPayment(data) {
        const url: string = GetApiurl('customer-wallet/');

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public getWalletDetails(id) {
        const url: string = GetApiurl(`customer-wallet/${id}/get-wallet-info/`);

        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public savePaymentDetails(data) {
        const url: string = GetApiurl('customer-transaction/');

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public getcustomerList(data) {
        const url: string = GetApiurl(`customer-offline-payments/get-customer-list/`);

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public getVendorList(data) {
        const url: string = GetApiurl(`customer-offline-payments/get-vendor-list/`);

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => err)
            );
    }

    public saveOfflinePayments(data) {
        const url: string = GetApiurl(`customer-offline-payments/`);

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
}
