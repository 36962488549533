import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from 'src/@fuse/services/config.service';
import { FuseSidebarService } from 'src/@fuse/components/sidebar/sidebar.service';

import { navigation } from 'src/app/navigation/navigation';
import { Router } from '@angular/router';
import { GlobalService, NotificationService, CustomerService } from 'src/app/services';

import { PubNubAngular } from 'pubnub-angular2';
import { pubnubKey } from 'src/app/parameters';
import { GenericsService } from 'src/app/services/generics.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services';
import { VendorService } from 'src/app/services/vendor.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    @ViewChild('appDataTable', { static: false }) appDataTable: any;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    user_details;
    notifications;
    notificationCount;
    customer_details: any;
    membership = '';
    expiry_date;
    customer_id;
    user_name;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private route: Router,
        private gs: GlobalService,
        private pubnub: PubNubAngular,
        private notificationService: NotificationService,
        private router: Router,
        private generalService: GenericsService,
        private dialog: MatDialog,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private cs: CustomerService,
        private ts: ToastrService,
        private vs: VendorService

    ) {
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];


        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.gs.updateName(undefined);
        this.getNotification();

        // Update User Name 
        this.gs.userDetails$.subscribe(value => {
            if(value) {
                this.vs.get_vendor_details(value).subscribe((data: any)=> {
                    if(data) {
                        if(data.company_name) {
                            this.user_name = data.company_name
                        }else {
                            this.user_name = data.first_name;
                        }
                    }
                })
            }
        })
        this.gs.user$.subscribe(userDetails => {
            this.user_details = userDetails;
            if(this.user_details && this.user_details.user_type == 'V') {
                this.vs.get_vendor_details(this.user_details.pk_id).subscribe((data: any)=> {
                    if(data) {
                        if(data.company_name) {
                            this.user_name = data.company_name
                        }else {
                            this.user_name = data.first_name;
                        }
                    }
                })
            }else {
                if(this.user_details && this.user_details.first_name) this.user_name = this.user_details.first_name
            }
        });
        if (this.user_details && this.user_details.user_type == 'C') {
            this.generalService.getLogindetails(this.user_details.pk_id, this.user_details.user_type)
                .subscribe(res => {
                    if (res['records']) {
                        this.customer_details = res['records'];
                        if(this.customer_details.hasOwnProperty('membership_details') != []){
                            if(this.customer_details['membership_details']){
                                this.membership = this.customer_details['membership_details'][0]['membership_detail']['membership_name'];
                                this.expiry_date = this.customer_details['membership_details'][0]['end_date'];
                            }
                        } 
                    }
                })
        }
        else if(this.user_details && this.user_details.user_type == 'S') {
            this.generalService.getLogindetails(this.user_details.pk_id, this.user_details.user_type)
                .subscribe(res => {
                    if (res['records']) {
                        this.customer_details = res['records']['customer_detail'];
                        this.customer_id = res['records']['customer'];
                        if(this.customer_details.hasOwnProperty('membership_details') != []){
                            if(this.customer_details['membership_details'] != []){
                                this.membership = this.customer_details['membership_details'][0]['membership_detail']['membership_name'];
                                this.expiry_date = this.customer_details['membership_details'][0]['end_date'];
                            }
                        }
                    }
                })
        }

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Pubnub Notification
        this.pubnub.init({
            publishKey: pubnubKey['publishKey'],
            subscribeKey: pubnubKey['subscribeKey'],
        });
        this.pubunub_notify();
        this.notificationService.status$.subscribe(
            data => {
                this.getNotification()
            }
        )
    }

    getNotification() {
        this.notificationService.getNotifications().subscribe(
            data => {
                if (data) {
                    this.notificationCount = data['count'];
                    this.notifications = data['records']
                } else {
                    this.notificationCount = 0;
                }
            }
        );
    }

    showNofifications() {
        this.route.navigate(['/app/notifications']);
    }

    showNotificationDetails(data) {
        this.notificationService.changeNotificationStatus(data.id).subscribe(
            dataValue => {
                this.getNotification()
            }
        );
        switch (data.subject) {

            case 'Customer':
                let url = `/app/admin/${data.details.id}/update/customer`;
                this.router.navigate([url]);
                break;

            case 'Vendor':
                this.router.navigate(['/app/vendor-profile', data.details.id, 'list']);
                break;

            case 'TicketMaster':
                if (this.user_details['is_superuser']) {
                    this.router.navigate(['/app/tickets', data.details.id, 'view']);
                } else if (this.user_details['user_type'] == 'C') {
                    this.router.navigate(['/app/bookings', data.details.id, 'ticket-detail-list']);
                }
                else if (this.user_details['user_type'] == 'V') {
                    this.router.navigate(['app/tickets/my-job', data.details.id, 'view']);
                }
                else {
                    this.router.navigate(['app/staff-tickets']);
                }
                break;

            case 'TicketDetail':
                if (this.user_details['is_superuser']) {
                    this.router.navigate(['/app/tickets', data.details.id, 'ticket-detail-view']);
                } else if (this.user_details['user_type'] == 'C') {
                    this.router.navigate(['app/bookings', data.details.id, 'ticket-detail-view']);
                } else if (this.user_details['user_type'] == 'V') {
                    this.router.navigate(['app/tickets/my-job', data.details.id, 'ticket-detail-view']);
                }
                else {
                    this.router.navigate(['app/staff-tickets']);
                }
                break;

            case 'VendorStaff':
                this.router.navigate(['/app/vendor-staff']);
                break;

            case 'Wallet':
                this.router.navigate(['/app/wallet/dashboard']);
                break;

            case 'VendorPO':
                this.router.navigate(['/app/vendor-profile', data.details.vendor, 'po-view', data.details.id]);
                break;
            case 'Services':
                this.router.navigate(['/app/services', data.details.id, 'sub-services']);
                break;
        }
    }

    pubunub_notify() {
        let channel = 'alserv';
        this.pubnub.subscribe({ channels: [channel], triggerEvents: true, withPresence: true });
        this.pubnub.getMessage(channel, (msg) => {
            this.getNotification();
        });
    }

    dependent() {
        this.route.navigate(['app/dependents/list'])
    }
    sponsor(){
        this.route.navigate([`app/sponsor/${this.user_details.pk_id}`])
    }
    customerProfile(){
        this.cs.getSelfCustomer(this.customer_id)
        .subscribe(res => {
            if(res['records'].length != 0){
            this.route.navigate([`app/customer-update/${res['records'][0]['id']}`]);
            }
        })
    }
    dependentProfile(){
        this.cs.getDependentforCustomer(this.customer_id)
        .subscribe(res => {
            if(res['records'].length != 0){
                this.route.navigate([`app/customer-update/${res['records'][0]['id']}`]);
            }
            else{
                this.ts.error("Dependent not available");
                this.route.navigate(['app/dashboard']);
            }
            
        })
    }
    backtoMembership() {
        this.route.navigate(['app/membership-dashboard'])
    }
    password() {
        this.dialog.open(PasswordModalComponent, {
            height: 'auto',
            width: '450px',
            data: {
                table: this.appDataTable,
                type: "Add",
                button_name: "Save",
                user_details: this.user_details
            },
        })
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }
    logout() {
        this.authService.logout();
    }
    profile() {
        if(this.user_details.user_type == 'V') {
            this.route.navigate([`/app/vendor/${this.user_details.pk_id}/profile`]);
        }else{
            this.route.navigate([`/app/users/profile/${this.user_details.id}`]);
        }
    }
    // alservUser(){
    //     console.log("Alserv user");
    // }
}

@Component({
    selector: 'password-service-modal',
    templateUrl: './password-service-modal.html',
})
export class PasswordModalComponent {
    user_details: any;
    title: string;
    button_name: any;
    setPasswordForm: FormGroup;
    changePasswordForm: FormGroup;
    progress: boolean;
    password_match: boolean;
    screen_name: any;


    constructor(
        private generic: GenericsService,
        public dialogRef: MatDialogRef<PasswordModalComponent>,
        private cs: CustomerService,
        private gs: GlobalService,
        private ts: ToastrService,
        private formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data['type'] + ' Service';
        this.button_name = data['button_name']
        this.user_details = data['user_details']
        if (this.user_details.is_superuser) {
            this.buildChangePasswordForm()
        }
        else {
            this.buildSetPasswordForm();
        }
    }
    ngOnInit() {
        if (this.user_details.user_type == 'C') {
            this.screen_name = 'customer'
        }
        else if (this.user_details.user_type == 'V') {
            this.screen_name = 'vendor'
        }
        else if (this.user_details.user_type == 'VS') {
            this.screen_name = 'staff'
        }
        else if(this.user_details.user_type == 'S'){
            this.screen_name = 'sponsor'
        }
        else {
            this.screen_name = 'others'
        }
    }
    buildChangePasswordForm(): any {
        this.changePasswordForm = this.formBuilder.group({
            'old_password': ['', Validators.required],
            'new_password': ['', Validators.required],
            'confirm_password': ['', Validators.required]
        })
    }
    buildSetPasswordForm() {
        this.setPasswordForm = this.formBuilder.group({
            'password': ['', Validators.required],
            'confirm_password': ['', Validators.required]
        })
    }
    checkPassword(password, event) {
        let new_password = event.target.value
        if (new_password == password) {
            this.password_match = false;
        }
        else {
            this.password_match = true;
        }
    }
    // To Set Password
    setPassword() {
        const password_form_value = this.setPasswordForm.value;
        // tslint:disable-next-line:variable-name
        let form_value = {};
        if (this.user_details.pk_id !== '') {
            form_value = {
                'pk_id': this.user_details.pk_id, 'screen_name': this.screen_name,
                'password': password_form_value['password']
            };
        } else {
            form_value = {
                'pk_id': this.user_details.id, 'screen_name': this.screen_name,
                'password': password_form_value['password']
            };
        }
        this.cs.setPassword(form_value).subscribe((data: any) => {
            this.setPasswordForm.reset()
            this.ts.success('Password Set Successfully,Please login and check')
            this.dialogRef.close(true)
        },
            error => {
                this.ts.error('Failed to set password', "Failed");
            }
        )
    }
    close() {
        this.dialogRef.close(false)
    }
    changePassword() {
        this.progress = true
        const password_form_value = this.changePasswordForm.value;
        const form_value = {

            'old_password': password_form_value['old_password'],
            'new_password': password_form_value['new_password']
        }
        this.generic.changePassword(form_value).subscribe((data: any) => {
            this.progress = false
            this.changePasswordForm.reset()
            this.ts.info(data)
            this.dialogRef.close(true)
        },
            error => {
                console.log(error, 123)
                this.ts.error('Failed to Change password', "Failed");
            }
        )
    }
}

