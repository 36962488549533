import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, AfterViewInit, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { GetApiurl } from 'src/app/parameters';
import { tableConfig, Column } from './interfaces';
import { Template } from '@angular/compiler/src/render3/r3_ast';

@Component({
    selector: 'app-ticketing-datatable',
    templateUrl: './ticketing-datatable.component.html',
    styleUrls: ['./ticketing-datatable.component.scss']
})
export class TicketingDatatableComponent implements OnInit {
    @Output() refreshedData: EventEmitter<any> = new EventEmitter();
    @Output() actionClickEvent: EventEmitter<any> = new EventEmitter();
    @Output() tableButton: EventEmitter<any> = new EventEmitter();
    @Output() rowClickEvent: EventEmitter<any> = new EventEmitter();

    displayedColumns: Array<any> = [];
    Columns: Array<Column> = [];
    backend: HttpDatabase | null;
    data: MatTableDataSource<any>;
    resultsLength = 0;
    isLoadingResults = true;
    isRateLimitReached = false;
    actions: string[];
    Config: tableConfig;
    searchText: any = "";

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(
        private _httpClient: HttpClient,
        private router: Router
    ) { }

    ngOnInit() {
    }

    @Input('tableConfig')
    set tableConfig(value) {
        this.Config = value || {};
    }
    @Input('columns')
    set columns(value) {
        this.Columns = value || [];
        this.Columns.forEach(ele => {
            this.displayedColumns.push(ele['prop']);
        });
    }

    ngAfterViewInit() {
        this.fetchRecords();
    }
    refreshTable() {
        this.fetchRecords();
    }
    fetchRecords() {
        this.backend = new HttpDatabase(this._httpClient);
        this.sort.sortChange.subscribe(() => {
            // this.paginator.pageIndex = 0
            // this.data.paginator = this.paginator;
            // if(this.data.paginator){
            //     this.data.paginator.firstPage()
            // }
        });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingResults = true;
                    return this.backend!.getRepoIssues(
                        this.Config['remoteUrl'], this.sort.active, this.sort.direction,
                        this.paginator.pageIndex, this.paginator.pageSize, this.searchText);
                }),
                map(data => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = false;
                    this.resultsLength = data.totalRecords;

                    return data.records;
                }),
                catchError(() => {
                    this.isLoadingResults = false;
                    this.isRateLimitReached = true;
                    return observableOf([]);
                })
            ).subscribe(data => this.data = new MatTableDataSource(data));
    }
    applyFilter(filterValue: string) {
        this.searchText = filterValue;
        this.fetchRecords();
    }
    addButton() {
        this.tableButton.emit(true)
    }
    eventEmit(event) {
        this.actionClickEvent.emit(event)
    }
    selectRow(row) {
        this.rowClickEvent.emit(row);
    }
}

export class HttpDatabase {
    constructor(private _httpClient: HttpClient) { }

    getRepoIssues(url: string, sort: string, order: string, page: number, pageSize: number, searchText: any = ""): Observable<any> {
        const offset = page * pageSize;
        const end = (page + 1) * pageSize;
        let key = "";
        if (order === "desc") {
            key = `-${sort}`;
        } else if (order === "asc") {
            key = sort;
        }
        const href = GetApiurl(url);
        const date = localStorage.getItem('date')
        var requestUrl = `${href}/?sort=${key}&offset=${offset}&end=${end}`
        if (date) {
            requestUrl = requestUrl + `&date=${date}`;
        }
        if (searchText) {
            requestUrl = requestUrl + `&searchText=${searchText}`;
        }
        return this._httpClient.get<any>(requestUrl);
    }
}
