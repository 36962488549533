export class OfficeDetails {
    constructor(
        public company_name?: string,
        public type?: string,
        public address_1?: string,
        public address_2?: string,
        public alternate_mobile?: string,
        public contract_period?: string,
        public office_proofs?: any,
        public proof_number?: string,
        public pan_number?: string,
        public tan_number?: string,
        public gst_type?: number,
        public gst_number?: string,
        public pf_est_regn?: string,
        public sme?: string,
        public licence_number?: string,
        public insurance_number?: string,
        public pin_code?: any,
        public state?: any,
        public city?: any,
        public first_name?: any,
        public last_name?: any,
        public email?: any,
        public mobile?: any,
        public id?: number,
        public logo_path?: any,
    ) { }
}

export class SelectVendorParams {
    serviceId: string;
    subService: string;
    TypeId: string;
    preferenceId: string;
    vendorId: any;

    constructor(data?: Object) {
        if (!(data instanceof Object)) data = {};
        this.serviceId = data['serviceId'] || null;
        this.subService = data['subService'] || null;
        this.TypeId = data['TypeId'] || null;
        this.preferenceId = data['preferenceId'] || null;
        this.vendorId = data['vendorId'] || null;
    }
}

export function proofDetails() {
    let items: Array<any> = [
        {'value':'pan_number', 'viewValue': 'Your PAN Number', 'choose': false },
        {'value':'tan_number', 'viewValue': 'Your TAN Number', 'choose': false },
        {'value':'licence_number', 'viewValue': 'Your License Number', 'choose': false },
    ]

    return items;
}