import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const token = localStorage.getItem('jwt_token');
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `JWT ${token}`,
                    'Content-Type': 'Application/json'
                }
            });
        }

         else {
          request = request.clone({
            setHeaders: {
                'Content-Type': 'Application/json'
            }
        });
        }

        return next.handle(request);
    }

    // Handeling error
    protected handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else if (error instanceof HttpErrorResponse) {
            errMsg = error.error ? error.error : error.toString();
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(errMsg);
    }
}
