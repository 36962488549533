import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';

// Services
import { RolesService } from '../../services';

// Classes
import { Roles } from '../../classes';

@Injectable()
export class RolesResolver implements Resolve<any> {
    constructor(private router: Router, private service: RolesService) { }

    resolve() {
        return this.service.getRoles()
            .toPromise()
            .then(user => user)
            .catch(
                Error => {
                    this.router.navigate(['/error/detail-not-found']);
                    return null
                });
    }
}

@Injectable()
export class RoleDetailsResolver implements Resolve<Roles> {
    constructor(private router: Router, private service: RolesService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.service.getRole(route.params.id)
            .toPromise()
            .then(user => user)
            .catch(
                Error => {
                    this.router.navigate(['/error/detail-not-found']);
                    return null
                });
    }
}
