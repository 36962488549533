import { environment, PUBNUBKEY, RazorpayId, media_base_url } from 'src/environments/environment';

export const baseURL = environment.baseurl;

export const MEDIA_BASE_URL = media_base_url
// get ApiURL
export function GetApiurl(path: string, params?: Object) {
  let url = baseURL + path;
  if (params instanceof Object) {
    url += '?';

    Object.entries(params).forEach(([param, value]) => {
      url += `${encodeURIComponent(param)}=${encodeURIComponent(value)}&`;
    });
    url = url.slice(0, -1);
  }

  return url;
}

const validImageTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'];
const validImageSize = 1048576;

const validFileTypes = ['application/pdf']
const validFileSize = 3048576

const validTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg', 'application/pdf'];
const validSize = 1048576;

const validxcelTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']

//get valid vendor image file types
export function GetValidimageTypes() {
  return { 'validType': validImageTypes, 'validSize': validImageSize }
}
export function GetValidfileTypes() {
  return { 'validType': validFileTypes, 'validSize': validFileSize }
}
export function GetValidTypes() {
  return { 'validType': validTypes, 'validSize': validSize }
}
export function GetValidxcelTypes() {
  return { 'validType': validxcelTypes, 'validSize': validSize }
}

export const UOM = [
  'Grams', 'Kilograms', 'Liters', 'Milliliters', 'Dozen', 'Pack', 'Piece'
];

export const pubnubKey = {
  publishKey: PUBNUBKEY.publishKey,
  subscribeKey: PUBNUBKEY.subscribeKey
}

export const getRazorpayId = {
  razorpay_id: RazorpayId.razorpay_id
}

//Alserv id
const razorpay_id = 'rzp_live_hbbFCgTggRp9EN'

//Membership gst hsn code
export const  MembershipGstHsn = {
  membership_gst_hsn : '999599'
}