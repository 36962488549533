import { Pipe, PipeTransform } from '@angular/core';
import { Symbols } from '../constants';
import { DEFAULT_INTERPOLATION_CONFIG } from '@angular/compiler';
import * as moment from 'moment'

// Capitalize pipe .....................................................................
@Pipe({ name: 'capitalize' })

export class CapitalizePipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) return value[0].toUpperCase() + value.slice(1);
        else return value;
    }
}

// Title Case pipe ......................................................................
@Pipe({ name: 'title' })

export class TitleCasePipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) return value[0].toUpperCase() + value.slice(1).toLowerCase();
        else return value;
    }
}

// Replaces underscores into spaces pipe .....................................................................
@Pipe({ name: 'u_to_space' })

export class UnderscoreToSpacePipe implements PipeTransform {
    transform(value, args: string[]): any {
        if (value) {
            return value.replace(/_/g, ' ');
        }
        else return value;
    }
}

// Symbol Conversion Pipe.....................................................................................................
@Pipe({ name: 'words_to_symbols' })

export class SymbolConversion implements PipeTransform {

    transform(value, args: string[]): any {
        if (value) {
            return value.replace(value, Symbols[value])
        }

    }

}

// Display Component Structure ................................................................................................................................................
@Pipe({ name: 'component_display' })

export class ComponentDisplay implements PipeTransform {
    transform(value, group: any, firstindex: any, secondindex: any): any {
        let return_string;
        value.forEach(groups => {
            groups.forEach(data => {
                if (data['fg' + (firstindex - 1) + secondindex] != undefined) {
                    if (data['fg' + (firstindex - 1) + secondindex].controls.component.value.name != group['fg' + firstindex + secondindex]['controls'].component.value.name) {
                        return_string = group['fg' + firstindex + secondindex]['controls'].component.value.name
                        return false;
                    } else return true
                }

            })
        })
        return return_string;
    }
}

// Display Component Type Structure .............................................................................................................................................................
@Pipe({ name: 'component_type_display' })

export class ComponentTypeDisplay implements PipeTransform {
    transform(value, group: any, firstindex: any, secondindex: any): any {
        let return_string;
        value.forEach(groups => {
            groups.forEach(data => {
                if (data['fg' + (firstindex - 1) + secondindex] != undefined) {
                    if (data['fg' + (firstindex - 1) + secondindex].controls.component_type.value.name == group['fg' + firstindex + secondindex]['controls'].component_type.value.name &&
                        data['fg' + (firstindex - 1) + secondindex].controls.component.value.name != group['fg' + firstindex + secondindex]['controls'].component.value.name) {
                        return_string = group['fg' + firstindex + secondindex]['controls'].component_type.value.name
                        return false;
                    } else if (data['fg' + (firstindex - 1) + secondindex].controls.component_type.value.name != group['fg' + firstindex + secondindex]['controls'].component_type.value.name) {
                        return_string = group['fg' + firstindex + secondindex]['controls'].component_type.value.name
                    } else return true
                }

            })
        })
        return return_string;
    }
}

// Change date format like("Tuesday 4th Sep 2018") .................................................................................................................................................
@Pipe({ name: 'DateFormat' })
export class DateFormatPipe implements PipeTransform {
    transform(value, args: string): any {
        if (value) {
            let date = moment(value).format('D MMM YYYY')
            // let date = moment(value).format('dddd Do MMM YYYY')
            return date
        }
        else return value
    }
}

// Change Date Time format like(Friday, September 7, 2018 3:36 PM) ....................................................................................................
@Pipe({ name: 'DateTimeFormat' })
export class DateTimeFormatPipe implements PipeTransform {
    transform(value, args: string): any {
        if (value) {
            let date = moment(value).format('LLLL')
            return date
        }
        else return value
    }
}

// Currency symbol ....................................................................................................
@Pipe({ name: 'CurrencySymbol' })
export class CurrencySymbol implements PipeTransform {
    transform(value, args: string): any {
        if (value) {
            let date = value
            return date
        }
        else return value
    }
}