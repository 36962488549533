import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

// Services
import { UsersService } from '../../services';

// Classes
import { User } from '../../classes';
// import { NotificationService } from '../../services/portal/notification.service';

@Injectable()
export class UserDetailResolver implements Resolve<any> {
    constructor(private router: Router, private service: UsersService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.service.getUser(route.params.id)
            .then(user => user)
            .catch(
                Error => {
                    this.router.navigate(['/error/detail-not-found']);
                    return null
                });
    }
}
