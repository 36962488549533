import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectiveModule } from '../directives/directive.module';
import { ToastrModule } from 'ngx-toastr';
import { ServicesModule } from '../services/services.module';
import {
    MatFormFieldModule, MatInputModule, MatButtonModule, MatCheckboxModule,
    MatIconModule, MatDividerModule, MatSlideToggleModule, MatButtonToggleModule,
    MatTableModule, MatMenuModule, MatTabsModule, MatOptionModule, MatListModule,
    MatSelectModule, MatGridListModule, MatPaginatorModule, MatChipsModule, MatRadioModule,
    MatSliderModule, MatDatepickerModule, MatAutocompleteModule, MatDialogModule,
    MatExpansionModule, MatNativeDateModule, MatProgressBarModule, MatRippleModule,
    MatSidenavModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatToolbarModule,
    MatTooltipModule,
    MAT_DATE_LOCALE
} from '@angular/material';
import { MatFileUploadModule } from 'mat-file-upload'
import { ResolverModule } from '../resolver/resolver.module';


import { FuseSharedModule } from 'src/@fuse/shared.module';
import {
    FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule,
    FuseWidgetModule, FuseHighlightModule
} from 'src/@fuse/components';
import { LayoutModule } from '../layout/layout.module';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { PipesModule } from '../pipes';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OverlayModule } from "@angular/cdk/overlay";
// import { DatatableComponent } from '../directives/datatable/datatable.component';
import { AngularFileUploaderModule } from "angular-file-uploader";

import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as highstock from 'highcharts/modules/stock.src';
// import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FuseModule } from 'src/@fuse/fuse.module';
import { PubNubAngular } from 'pubnub-angular2';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
    imports: [
        // Angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
    DirectiveModule,
        ToastrModule.forRoot({
            progressBar: true,
            enableHtml: true,
            timeOut: 2000,
            positionClass: 'toast-center-center',
            toastClass: "toast toaster-class",
        }),
        HttpClientModule,
        ServicesModule,

        MatFormFieldModule,
        MatInputModule,
        FuseProgressBarModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        LayoutModule,
        MatDividerModule,
        FuseWidgetModule,
        MatSlideToggleModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatTabsModule,
        MatOptionModule,
        MatListModule,
        MatSelectModule,
        MatGridListModule,
        ResolverModule,
        MatTableModule,
        PipesModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatPaginatorModule,
        NgxDatatableModule,
        NgxMatSelectSearchModule,
        MatChipsModule,
        // MatStepperModule,
        // MatDialogModule,
        OverlayModule,
        MatRadioModule,
        MatSliderModule,
        MatFileUploadModule,
        AngularFileUploaderModule,
        ChartModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSortModule,
        // MatStepperModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        FuseSharedModule,
        PdfViewerModule
    ],
    exports: [
        // Angular modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        DirectiveModule,
        ToastrModule,
        HttpClientModule,
        ServicesModule,
        MatFormFieldModule,
        MatInputModule,
        FuseSharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        LayoutModule,
        FuseProgressBarModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        MatDividerModule,
        FuseWidgetModule,
        MatSlideToggleModule,
        FuseHighlightModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatTabsModule,
        MatOptionModule,
        MatListModule,
        MatSelectModule,
        MatGridListModule,
        ResolverModule,
        MatTableModule,
        PipesModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        NgxDatatableModule,
        MatPaginatorModule,
        NgxMatSelectSearchModule,
        MatChipsModule,
        // MatStepperModule,
        // MatDialogModule,
        OverlayModule,
        MatRadioModule,
        MatSliderModule,
        MatFileUploadModule,
        AngularFileUploaderModule,
        ChartModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatRippleModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSortModule,
        // MatStepperModule,
        MatToolbarModule,
        MatTooltipModule,
        NgxMaterialTimepickerModule,
        FuseSharedModule,
        PdfViewerModule
    ],
    declarations: [

    ],
    providers: [
        PubNubAngular,
        { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] }, // add as factory to your providers
        { provide: HIGHCHARTS_MODULES, useFactory: () => [highstock] },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
