import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from 'src/@fuse/components';
import { FuseSharedModule } from 'src/@fuse/shared.module';

import { ToolbarComponent, PasswordModalComponent } from 'src/app/layout/components/toolbar/toolbar.component';
import { MatBadgeModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule} from '@angular/material';
import { PipesModule } from '../../../pipes';

@NgModule({
    declarations: [
        ToolbarComponent,
        PasswordModalComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MatBadgeModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,      
        MatFormFieldModule ,
        MatInputModule,
        MatProgressSpinnerModule,
        PipesModule,
        MatDialogModule
    ],
    exports     : [
        ToolbarComponent,
        PipesModule ,
        MatDialogModule  
    ],
    entryComponents: [
        PasswordModalComponent
    ]
})
export class ToolbarModule
{
}
