import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Pipes
import { CapitalizePipe, TitleCasePipe, UnderscoreToSpacePipe, SymbolConversion, ComponentDisplay, ComponentTypeDisplay, DateFormatPipe, DateTimeFormatPipe, CurrencySymbol } from './pipes';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CapitalizePipe,
        TitleCasePipe,
        UnderscoreToSpacePipe,
        SymbolConversion,
        ComponentDisplay,
        ComponentTypeDisplay,
        DateFormatPipe,
        DateTimeFormatPipe,
        CurrencySymbol
    ],
    exports: [
        CapitalizePipe,
        TitleCasePipe,
        UnderscoreToSpacePipe,
        SymbolConversion,
        ComponentDisplay,
        ComponentTypeDisplay,
        DateFormatPipe,
        DateTimeFormatPipe,
        CurrencySymbol
    ]
})
export class PipesModule { }
