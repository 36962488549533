import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlimSearchComponent } from './slim-search/slim-search.component';
import { MatChipsModule, MatIconModule, MatButtonModule, MatAutocompleteModule, MatInputModule, MatCheckboxModule, MatProgressSpinnerModule } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
    declarations: [
        SlimSearchComponent
    ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule
    ],
    exports: [
        SlimSearchComponent
    ]
})
export class ExtendedSearchModule { }
