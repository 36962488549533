import { NgModule } from '@angular/core';

import {UserDetailResolver, RoleDetailsResolver, RolesResolver } from './index';

@NgModule({
    providers: [
        UserDetailResolver,
        RoleDetailsResolver,
        RolesResolver
    ]
})
export class ResolverModule { }
