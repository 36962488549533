import { Injectable } from '@angular/core';
import { CommonMatModelComponent, SuccessModelComponent } from './common-mat-model.component';
import { MatDialog } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class CustomMessageModel {
    loader: any;
    constructor(
        private dialog: MatDialog
    ) { }

    /**
     * 
     * @param message warning message. This support html format.
     * @param title Title like Failed, success, etc...
     * @param width Default 50%
     * @param disableClose Default False
     */
    warning(message: string, title: string, width: string = "20%", disableClose: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(CommonMatModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        icon: "warning",
                        message: message,
                        color: "warn",
                        loader: false
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }

    /**
     * 
     * @param message Error message. This support html format.
     * @param title Title like Failed, success, etc...
     * @param width Default 50%
     * @param disableClose Default False
     */
    error(message: string, title: string, width: string = "50%", disableClose: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(CommonMatModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        icon: "close",
                        message: message,
                        color: "warn",
                        loader: false
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }
    
    /**
     * 
     * @param message Info message. This support html format.
     * @param title Title like Failed, success, etc...
     * @param width Default 50%
     * @param disableClose Default False
     */
    info(message: string, title: string, width: string = "50%", disableClose: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(CommonMatModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        icon: "info",
                        message: message,
                        color: "accent",
                        loader: false
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }

    /**
     * 
     * @param message Confirm message. This support html format.
     * @param title Title like Failed, success, etc...
     * @param width Default 50%
     * @param disableClose Default False
     */
    confirm(message: string, title: string, width: string = "50%", disableClose: boolean = true): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(CommonMatModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        icon: "info",
                        confirm: true,
                        message: message,
                        color: "accent",
                        loader: false
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }

    /**
     * 
     * @param message Success message. This support html format
     * @param title Required
     * @param width Optional
     * @param disableClose Optional
     */
    success(message: string, title: string, width: string = "50%", disableClose: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(CommonMatModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        icon: "done",
                        message: message,
                        color: "accent",
                        loader: false
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }

    /**
     * 
     * @param message Array filed  => *Required filed.
     * @param title String filed
     * @param width Optional
     * @param disableClose Optional
     */
    successMultipleLine(message: Array<string>, title: string, width: string = "50%", disableClose: boolean = false): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const dialogRef = this.dialog.open(SuccessModelComponent, {
                    width: width,
                    disableClose: disableClose,
                    data: {
                        title: title,
                        message: message
                    }
                });
                dialogRef.afterClosed().subscribe(res => resolve(res));
            } catch (err) {
                reject(err.message);
            }
        });
    }

    /**
     * 
     * @param message Optional
     * @param title Optional
     * @param width Optional
     * @param disableClose Optional
     */
    openLoader(message: string = "Please wait ...", title: string = "", width: string = "20%", disableClose: boolean = true) {
        this.loader = this.dialog.open(CommonMatModelComponent, {
            width: width,
            disableClose: disableClose,
            data: {
                title: title,
                icon: "warning",
                message: message,
                color: "accent",
                loader: true
            }
        });
    }
    closeLoader() {
        this.loader.close();
    }
}