import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { JwtInterceptor } from '../jwt-interceptor.service';


@Injectable()
export class VendorModelService extends JwtInterceptor {

    constructor(private http: HttpClient) { super(); }

    public GetVendorStaffList(id) {
        const url: string = GetApiurl(`vendor/get-vendor-staff-list/?detail_id=${id}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError)
            );
    }
    public GetVendorService(id) {
        const url: string = GetApiurl(`vendor-services/${id}/get-vendor-service/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError)
            );
    }

    public addVendorItems(data) {
        const url = GetApiurl(`vendor-services/create-vendor-items/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public activateVendor(data) {
        const url = GetApiurl(`vendor-po/activate-vendor/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_supply_service(id) {
        const url = GetApiurl(`supply-service/${id}/get-supply-service/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addVendorSupplyServiceItems(data) {
        const url = GetApiurl(`supply-service/create-supply-service-items/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_vendor_zones(id, value) {
        const url = GetApiurl(`vendor-po/${id}/get-vendor-zones/`);
        return this.http
            .post(url, value)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getVendorPoList() {
        const url = GetApiurl(`vendor-po/get-vendor-po/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public getPOFilter() {
        const url = GetApiurl(`vendor-po/get-po-filter`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getFilterData(vendorId, filter_type, previous_id?: any) {
        const url = GetApiurl(`vendor-po/get-vendor-po-data/${vendorId}?filter_type=${filter_type}&previous=${previous_id}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addVendorPo(data) {
        const url = GetApiurl(`vendor-po/vendor-po-creations/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public amendVendorPo(id, data) {
        const url = GetApiurl(`vendor-po/${id}/vendor-po-amendment/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getVendorPo(id, params?: any) {
        let url;
        if (params && Object.keys(params).length > 0) {
            url = GetApiurl(`vendor-po/${id}/?offset=${params.offset}&end=${params.end}`);
        } else {
            url = GetApiurl(`vendor-po/${id}/`);
        }
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public sendVendor(id) {
        const url = GetApiurl(`vendor-po/send-vendor/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public sendAlserv(id) {
        const url = GetApiurl(`vendor-po/send-to-alserv/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }


    public getVendorDasboardDetails(id) {
        const url = GetApiurl(`ticket-master/get-vendor-dashboard-details/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public registrationValidation(data) {
        const url = GetApiurl(`vendor/enable-registration/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_items(data) {
        const url = GetApiurl(`service-type/get-items/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_supply_items(data) {
        const url = GetApiurl(`service-type/get-supply-items/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getServices(id) {
        const url = GetApiurl(`vendor/my-services/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public saveAllZones(data) {
        const url = GetApiurl(`vendor/add-all-zones/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getServicesItems(id) {
        const url = GetApiurl(`service-items/${id}/get-service-items/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public GetVendorReports(report, year?: any) {
        const url: string = GetApiurl(`reports/vendor_by_zone_services/?report=${report}&year=${year} `)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public downloadVendorPdf(data, year?: any) {
        const url: string = GetApiurl(`reports/download-vendors-pdf/?year=${year}`)
        return this.http.post(url, data, {
            observe: 'response',
            responseType: 'blob',
        })
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public downloadVendorExcel(data, year?: any) {
        const url: string = GetApiurl(`reports/download-vendors-excel/?year=${year}`)
        return this.http.post(url, data, {
            observe: 'response',
            responseType: 'blob',
        })
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public downloadOverallPdf(data, year?: any) {
        const url: string = GetApiurl(`reports/download-overall-pdf/?year=${year}`)
        return this.http.post(url, data, {
            observe: 'response',
            responseType: 'blob',
        })
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public downloadOverallCsv(data, year?: any) {
        const url: string = GetApiurl(`reports/download-overall-csv/?year=${year}`)
        return this.http.post(url, data, {
            observe: 'response',
            responseType: 'blob',
        })
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public GetTicketsReports() {
        const url: string = GetApiurl(`reports/tickets_by_service/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
    // Get the count of zones and services for a vendor
    public getServiceZonesCount() {
        return this.http.get(GetApiurl(`reports/vendor-service-zones/`))
            .pipe(tap(Response => {
                return Response;
            }))
    }

    // Get all the zones and services for the vendor
    public getServiceZonelist(vendor_id) {
        return this.http.get(GetApiurl(`reports/get-vendor-zones/${vendor_id}`))
            .pipe(tap(Response => {
                return Response;
            }),
                catchError(this.handleError))
    }
    public GetClosedTicketsReports(year, status?: any, category?: any, filter?: any) {
        let url: string = '';
        if (category) {
            url = GetApiurl(`reports/closed-tickets/?year=${year}&status=${status}&category=${category}&filter=${filter}`)
        } else {
            url = GetApiurl(`reports/closed-tickets/?year=${year}`)
        }
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    public GetTotalTicketsReports(filter) {
        const url: string = GetApiurl(`reports/total_ticket_count/?filter=${filter}`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
    // get total jobs of a vendor
    public getTotalJobs(vendor, status, year?: any) {
        return this.http.get(GetApiurl(`reports/total-vendor-jobs/${vendor}/?status=${status}&year=${year}`))
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError))
    }
    // get total general ticekts
    public getTotalTickets(vendor, status, year?: any) {
        return this.http.get(GetApiurl(`reports/total-vendor-tickets/${vendor}/?status=${status}&year=${year}`))
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError))
    }
    // get total po
    public getTotalPo(vendor, status, year?: any) {
        // `reports/total-vendor-po/${vendor}/?status=${status}`
        return this.http.get(GetApiurl(`reports/total-vendor-po/${vendor}/?status=${status}&year=${year}`))
            .pipe(tap(Response => {
                return Response
            }),
                catchError(this.handleError))
    }
    // get vendor jobs based on status
    public getVendorJobStatus(vendor, month?: any) {
        return this.http.get(GetApiurl(`reports/get-vendor-status-details/${vendor}/?month=${month}`))
            .pipe(tap(Response => {
                return Response
            }))
    }

    // get vendor ticket status details
    public getVendorTicketStatus(vendor, month?: any) {
        return this.http.get(GetApiurl(`reports/get-vendor-ticket-details/${vendor}/?month=${month}`))
            .pipe(tap(Response => {
                return Response
            }))
    }

    // get vendor po status details
    public getVendorPOStatus(vendor, month?: any) {
        return this.http.get(GetApiurl(`reports/get-vendor-po-details/${vendor}/?month=${month}`))
            .pipe(tap(Response => {
                return Response
            }))
    }
    //get vendor using pincode details
    public getVendorListPincode(value, type) {
        const url = GetApiurl(`vendor/get-vendor-list-pincode/${value},${type}/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res
                }),
                catchError(this.handleError)
            )
    }

    public getMenuItems(id, vendor_id, vendor_po, params) {
        const url: string = GetApiurl(`vendor/${id}/menu-items/${vendor_id}/${vendor_po}/`, params);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
}

