import { Injectable, Inject } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationExtras
} from '@angular/router';

// Services
import { AuthService } from './authservice';

// Classes
import { User } from '../../classes';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from './globalservice';

@Injectable()
export class AuthGuardService implements CanActivate {

  private urlSource = new BehaviorSubject<string>(null);
  urlItems$ = this.urlSource.asObservable()
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.urlSource.next(state.url)
    // Validating url params
    if (Object.keys(route.data).indexOf('regex') >= 0) {
      let params = route.params;
      for (let param of Object.keys(params)) {
        if (Object.keys(route.data['regex']).indexOf(param) >= 0) {
          let regex_str = `^${route.data['regex'][param]}$`;
          let exp = new RegExp(regex_str, 'g');

          if (!exp.test(params[param])) {
            this.router.navigate(['/error/not-found']);
            return false;
          }
        }
      }
    }

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/auth/login']);
      return false;
    }

    // Checks permission if roles provided
    if (Object.keys(route.data).indexOf('roles') == -1) return true;
    else {
      let userTypes: Array<string> = null;

      if (Object.keys(route.data).indexOf('userTypes') >= 0) userTypes = route.data['userTypes'];

      if (this.authService.permitted(route.data['roles'], userTypes)) return true;
      else {
        this.router.navigate(['/error/permission-denied']);
        return false;
      }
    }

  }

}

// Authenticated redirecting services, it helps to redirect directly to homepage if the user is authenticated already
@Injectable()
export class AuthRedirectorService implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url
    // Redirecting Authenticated user
    if (this.authService.isAuthenticated()) {

      this.authService.redirectToHome();
      return false;

    } else return true;
  }

}

// User based redirect process
@Injectable()
export class UserBasedRedirectorService implements CanActivate {
  user_details: any

  constructor(private router: Router, private authService: AuthService, private globals: GlobalService) {
    // User details subscription
    this.globals.user$.subscribe(details => {
      if (details) this.user_details = details;
    });

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let url: string = '/app/dashboard';
    if (this.user_details.user_type == 2) url = '/app/customer-reports';

    this.router.navigateByUrl(url)

    return true;

  }

}
