import { HttpClient } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ServicesService {

    constructor(private http: HttpClient) { }

    /**
     * Service Master Releted services
     */

    public addImage(formvalues, filedata: File) {
        const url = GetApiurl('services/');
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('image', filedata, filedata.name);
                }
                for (var key in formvalues) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (formvalues.hasOwnProperty(key)) {
                        formData.append(key, formvalues[key]);
                    }
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        if (xhr.status == 200) {
                            resolve(JSON.parse(xhr.responseText));
                        }
                        else {
                            return throwError(xhr.statusText);
                        }
                    }
                };
            });
        }
    }
    public getAllServices() {
        const url = GetApiurl(`services/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public getService(id) {
        const url = GetApiurl(`services/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateService(data, id) {
        const url = GetApiurl(`services/${id}/`);
        return this.http
            .put(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateImage(formvalues, filedata: File, id) {
        const url = GetApiurl(`services/${id}/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('image', filedata, filedata.name);
                }
                for (var key in formvalues) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (formvalues.hasOwnProperty(key)) {
                        formData.append(key, formvalues[key]);
                    }
                }
                xhr.open('PUT', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
    }
    public deleteService(id) {
        const url = GetApiurl(`services/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public checkServiceExists(data) {
        let url = GetApiurl('services/check-service-exist/')
        return this.http.post(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError))
    }
    public checkHsnCode(data) {
        let url = GetApiurl('services/check-hsn-code/')

        return this.http.post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError))
    }
    /**
     * Service Details Releted services
     */
    public getAllServicesDetails(id) {
        const url = GetApiurl(`sub-service/?service_id=${id}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public getAllSubServices() {
        const url = GetApiurl(`sub-service/get-all-sub-services/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public getServiceDetails(id) {
        const url = GetApiurl(`sub-service/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public addServiceDetials(data, filedata: File) {
        const url = GetApiurl(`sub-service/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('image', filedata, filedata.name);
                }
                for (var key in data) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
        // return this.http
        //   .post(url, JSON.stringify(data))
        //   .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateServiceDetials(data, id, filedata: File) {
        const url = GetApiurl(`sub-service/${id}/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('image', filedata, filedata.name);
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
        // return this.http
        //   .put(url, JSON.stringify(data))
        //   .pipe(map(Response => Response), catchError(this.handleError));
    }
    public deleteServiceDetials(id) {
        const url = GetApiurl(`sub-service/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    // Frequency related services
    public getFrequency(id) {
        const url = GetApiurl(`service-frequency/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addFrequency(data) {
        const url = GetApiurl(`service-frequency/`);
        return this.http
            .post(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateFrequency(data, id) {
        const url = GetApiurl(`service-frequency/${id}/`);
        return this.http
            .put(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public deleteFrequency(id) {
        const url = GetApiurl(`service-frequency/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    // Service Type related services
    public getServiceAllType() {
        const url = GetApiurl(`service-type/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getServiceType(id) {
        const url = GetApiurl(`service-type/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addAlservPrice(data) {
        const url = GetApiurl(`service-type/update-alserv-price/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addServiceType(data, filedata: File) {
        const url = GetApiurl(`service-type/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('image', filedata, filedata.name);
                }
                for (var key in data) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
        // return this.http
        //   .post(url, JSON.stringify(data))
        //   .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateServiceType(data, id, filedata: File) {
        const url = GetApiurl(`service-type/${id}/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('image', filedata, filedata.name);
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });

        // return this.http
        //   .put(url, JSON.stringify(data))
        //   .pipe(map(Response => Response), catchError(this.handleError));
    }
    public deleteServiceType(id) {
        const url = GetApiurl(`service-type/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public addServiceMenu(data, preference, filedata: File) {
        const url = GetApiurl(`menu/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('file_path', filedata, filedata.name);
                }
                for (var key in data) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('POST', url + `?service_type=${preference['service_type']}&service_preference=${preference['service_preference']}`, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
    }
    public updateServiceItem(data, id, filedata: File) {
        const url = GetApiurl(`menu/edit-menu-item/${id}/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('file_path', filedata, filedata.name);
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });

    }
    public deleteServiceItem(id) {
        const url = GetApiurl(`menu/delete-menu-item/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public deleteCombo(id) {
        const url = GetApiurl(`menu/delete-menu-combo/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public addCombo(data) {
        const url = GetApiurl(`menu/add-menu-combo/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    // preference releated service
    public addPreference(data, filedata: File) {
        const url = GetApiurl(`service-preference/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('image', filedata, filedata.name);
                }
                for (var key in data) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
            // return this.http
            //   .post(url, JSON.stringify(data))
            //   .pipe(map(Response => Response), catchError(this.handleError));
        }
    }
    public updatePreference(data, id, filedata: File) {
        const url = GetApiurl(`service-preference/${id}/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('image', filedata, filedata.name);
            }
            for (var key in data) {
                // check if the property/key is defined in the object itself, not in parent
                if (data.hasOwnProperty(key)) {
                    formData.append(key, data[key]);
                }
            }
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
        // return this.http
        //   .put(url, JSON.stringify(data))
        //   .pipe(map(Response => Response), catchError(this.handleError));
    }
    public deletePreference(id) {
        const url = GetApiurl(`service-preference/${id}/`);
        return this.http
            .delete(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public retrievePreference(id) {
        const url = GetApiurl(`service-preference/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public retrieveServiceItem(id) {
        const url = GetApiurl(`service-items/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    // Handeling error
    public handleError(error: Response | any) {
        let errMsg: string;
        let details;
        if (error instanceof Response) {
            const body = error.json() || "";
            const err = body["error"] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
            details = { detail: body, status: err.status };
        } else {
            errMsg = error.message ? error.message : error.toString();
            details = error.error;
        }
        return throwError(details);
    }

    //Service Item 
    public addServiceItem(data) {
        const url = GetApiurl('service-items/')
        return this.http.post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    // Service Items related services
    public getServiceItems() {
        const url = GetApiurl(`service-items/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getScrollItems(params) {
        const url = GetApiurl(`service-items/`, params);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getServiceItem(id) {
        const url = GetApiurl(`service-items/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateItem(data, id) {
        const url = GetApiurl(`service-items/${id}/`);
        return this.http
            .put(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateChanges(data, id) {
        const url = GetApiurl(`customer-tickets/${id}/`);
        return this.http
            .put(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    //HSN Services

    addHsn(data) {
        let url = GetApiurl('gst-rate/')
        return this.http.post(url, data)
            .pipe(map(Response => Response),
                catchError(this.handleError))
    }
    updateHsn(data, id) {
        let url = GetApiurl(`gst-rate/${ id }/`);
        return this.http.put(url, data)
            .pipe(map(Response => Response),
                catchError(this.handleError));
    }

    // add alserv profile details
    public addAlservProfile(value, logo, sign){
        const url = GetApiurl(`alserv-profile/`)
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if(logo != undefined){
                formData.append('logo', logo, logo.name)
            }
            if(sign != undefined){
                formData.append('signature', sign, sign.name)
            }
            if(value['customer_terms_condition'] !='') {
                formData.append('customer_terms_condition', value['customer_terms_condition'])
            }
            if(value['vendor_terms_condition'] !='') {
                formData.append('vendor_terms_condition', value['vendor_terms_condition'])
            }
            formData.append('form_data', JSON.stringify(value))
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
    }

    // Update Alserv Profile
    public updateAlservProfile(id, value, logo, sign){
        const url = GetApiurl(`alserv-profile/${id}/`)
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if(logo != undefined){
                formData.append('logo', logo, logo.name)
            }
            if(sign != undefined){
                formData.append('signature', sign, sign.name)
            }
            if(value['customer_terms_condition'] !='') {
                formData.append('customer_terms_condition', value['customer_terms_condition'])
            }
            if(value['vendor_terms_condition'] !='') {
                formData.append('vendor_terms_condition', value['vendor_terms_condition'])
            }
            formData.append('form_data', JSON.stringify(value))
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
    }

    // Get Alserv Profile records
    public getAlservProfile(){
        const url = GetApiurl('alserv-profile/')
        return this.http.get(url)
        .pipe(map(response => {
            return response
        }) )
    }


    // --- Upload Excel File -- //
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
        FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
    }

    public uploadExcel(filedata: File, sub_service_id?: number, service_id?: number, service_type?:number) {
        const url = GetApiurl(`common/upload-xslx/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('file', filedata, filedata.name);
            }
            formData.append('subservice', sub_service_id)
            formData.append('service', service_id)
            formData.append('service_type', service_type)
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        }).catch(Error => Error)
    }

    public uploadPOExcel(filedata: File) {
        const url = GetApiurl(`common/upload-po-xslx/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('file', filedata, filedata.name);
            }
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        }).catch(Error => Error)
    }


    // Check if membership presents in gst table
    public checkHsn(hsn){
        return this.http.get(GetApiurl(`alserv-profile/check-hsn/`), {
            params: {
                'hsn': hsn
            }
        })
        .pipe(map(response => {
            return response
        }))
    }

    // Save Sponsor details
    public saveSponsor(form_value){
        return this.http.post(GetApiurl(`sponsor/`), form_value)
        .pipe(map(Response => {
            return Response
        }))
    }
    // Update sponsor details
     public updateSponsor(id, value){
        return this.http.put(GetApiurl(`sponsor/${id}/`), value)
        .pipe(map(Response => {
            return Response
        }))
    }
    
    // Get sponsor record for a customer
    public getSponsor(customer_id){
        return this.http.get(GetApiurl(`sponsor/${customer_id}/`))
        .pipe(map(Response => {
            return Response
        }))
    }
}
