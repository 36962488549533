import { PipeTransform, Pipe } from '@angular/core';

// Custom Datatable coloum ...........................................................................................................
@Pipe({ name: "NormalColumn" })
export class NormalColumn implements PipeTransform {
    transform(value, args: string) {
        try {
            let data = args.split('.');
            if (data.length > 1) {
                let temp = value;
                data.forEach(element => {
                    temp = temp[element]
                });
                return temp;
            }
            else {
                return value[args];
            }
        } catch{
            return "-----";
        }
    }
}