import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// -- Services --
import { ValidationService } from '../services/validation/validation.service';

@Component({
    selector: 'control-messages',
    template: `
<div *ngIf="errorMessage !== null" class="form-error">{{ errorMessage }}</div>`
})
export class ControlMessagesComponent {
    _control: FormControl;

    @Input() set control(ctrl: FormControl) {
        this._control = ctrl;
    }
    constructor() { }

    get errorMessage() {
        for (const propertyName in this._control.errors) {
            if (this._control.errors.hasOwnProperty(propertyName) && this._control.touched) {
                const errorMsg: string =
                ValidationService.getValidatorErrorMessage(propertyName, this._control.errors[propertyName], getControlName(this._control));

                if (errorMsg) {
                    return errorMsg;
                } else {
                    return this._control.errors[propertyName];
                }
            }
        }

        return null;
    }
}



// Find control name from FormControl
export const getControlName = (control: any) => {
    let controlName = null;
    const parent = control['_parent'];

    // only such parent, which is FormGroup, has a dictionary 
    // with control-names as a key and a form-control as a value
    if (parent instanceof FormGroup) {
        // now we will iterate those keys (i.e. names of controls)
        Object.keys(parent.controls).forEach((name) => {
            // and compare the passed control and
            // a child control of a parent - with provided name (we iterate them all)
            if (control === parent.controls[name]) {
                // both are same: control passed to Validator
                //  and this child - are the same references
                controlName = name;
            }
        });
    }
    // we either found a name or simply return null
    return controlName;
};
