import { AuthGuardService, AuthRedirectorService } from './services/authservice/authguardservice';
import { AgmCoreModule } from '@agm/core';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from 'src/@fuse/fuse.module';
import { fuseConfig } from 'src/app/fuse-config';
import { AppInit } from './app.init';
import { GlobalService, AuthService, NotificationService } from './services';
import { ErrorModule } from './error/error.module';
import {CalendarService} from './features/remainder/remainder.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { VendorService } from './services/vendor.service';


export function tokenGetter() {
  return localStorage.getItem("jwt_token");
}
export function userProviderFactory(provider: AppInit) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PdfViewerModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    TranslateModule.forRoot(),
    FuseModule.forRoot(fuseConfig),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAT8k7s9wkpRBi4YiHNzi4WvFeRwZGOafM',
      libraries: ['places']
    }),
    ErrorModule
  ],
  exports: [
    TranslateModule,
  ],
  providers: [
    AppInit,
    GlobalService,
    AuthGuardService,
    AuthRedirectorService,
    AuthService,
    CalendarService,
    NotificationService,
    VendorService,
    { provide: APP_INITIALIZER, useFactory: userProviderFactory, deps: [AppInit], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
