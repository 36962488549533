import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Constants
import { GetApiurl } from '../../parameters';
import { Observable } from 'rxjs';

// Classes
import { User } from '../../classes';
// import { resolve } from 'url';
import { BehaviorSubject, from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VendorService } from '../vendor.service';
import { JwtInterceptor } from '../jwt-interceptor.service'

@Injectable()
export class UsersService extends JwtInterceptor{
    constructor(
        private http: HttpClient,
        private vendorService: VendorService
        // private router: Router,
    ) { 
        super();
    }
    getUSerList(data) {
        let url = GetApiurl('users/')
        return this.http.get(url, { params: { 'offset': data['offset'] ? data['offset'] : 0, 'end': data['end'] ? data['end'] : 5, 'search_text': data['search_text'] ? data['search_text'] : null } })
            .pipe(map(res => res), catchError(Error => this.vendorService.handleError(Error)))
    }
    addUser(user, current_user, filedata: File) {
        // cons
        let url: string = GetApiurl('users/');
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata) {
                formData.append('profile', filedata, filedata.name);
            }
            formData.append('data',JSON.stringify(user))
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
        // user['image'] = imageFile;
        // let url: string = GetApiurl('users/');
        // let header: HttpHeaders = new HttpHeaders();
        // const headers = header.append('Accept', 'application/json');
        // return this.http
        //     .post(url, JSON.stringify(user), {
        //         headers, params: current_user
        //     })
        //     .toPromise()
        //     .then((Response: any) => Response)
        //     .catch(this.handleError);
    }
    getUser(id: number) {
        const url: string = GetApiurl(`users/${id}/`);
        const header: HttpHeaders = new HttpHeaders();
        const headers = header.append('Accept', 'application/json');
        return this.http
            .get(url)
            .toPromise()
            .then((response) => response)
            .catch(this.handleError);
    }
    updateUser(user: User,  cityValue?:any , filedata?: File) {
        let url: string = GetApiurl(`users/${user.id}/`);
        // let url: string = GetApiurl('users/');
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata) {
                formData.append('profile', filedata, filedata.name);
            }
            for (var key in user) {
                // check if the property/key is defined in the object itself, not in parent
                if (user.hasOwnProperty(key)) {
                    formData.append(key, user[key]);
                }
            }
            formData.append('data',JSON.stringify(user))
            xhr.open('PUT', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
        // const header: HttpHeaders = new HttpHeaders();
        // const headers = header.append('Accept', 'application/json');

        // return this.http
        //     .put(url, JSON.stringify(user))
        //     .toPromise()
        //     .then(res => res)
        //     .catch(this.handleError);
    }
    public userDetails(id) {
        const url = GetApiurl('users/get-user-details/')
        return this.http.get(url,
            {
                params: {
                    'user_id': id
                }
            })
            .toPromise()
            .then(Response => {
                return Response
            })
            .catch(this.handleError)
    }

    public getPassswordTrackingDetails(id) {
        const url = GetApiurl('users/track-user-password/')
        return this.http.get(url,{
                params: {
                    'user_id': id
                }
            })
            .toPromise()
            .then(Response => {
                return Response
            })
            .catch(this.handleError)
    }

    public uploadDependentDocument(data, document: File){
        const url = GetApiurl('dependents/add-dependent-docs/')
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if ( document != undefined){
            formData.append('docs', document);
            }
            formData.append('dependent', data);
            xhr.open('POST', GetApiurl('dependents/add-dependent-docs/'), true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            // xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });
    }

    public getAccessibleCities(){
        let url = GetApiurl('users/accessible-cities/')   
        return this.http.get(url)
            .toPromise()
            .then(Response => {
                return Response
            })
            .catch(this.handleError)
    }

    public getAccessibleZones(city_name:any){
        let url = GetApiurl(`users/accessible-zones/${city_name}/`)
        return this.http.get(url)
        .toPromise()
        .then(Response => {
            return Response
        })
        .catch(this.handleError)
    }
}
