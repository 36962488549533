import { Component, OnInit, Input } from '@angular/core';
import { GlobalService, CustomerWalletService } from 'src/app/services';

@Component({
  selector: 'app-customer-balance-widget',
  templateUrl: './customer-balance-widget.component.html',
  styleUrls: ['./customer-balance-widget.component.scss']
})
export class CustomerBalanceWidgetComponent implements OnInit {
  @Input() customerId: string;
  walletDetails: any;
  
  constructor(private globalService: GlobalService,
              private walletService: CustomerWalletService,  
  ) { }

  ngOnInit() {
    if (!this.customerId) {
			var customer = localStorage.getItem('customerId')
			if (customer) {
				this.customerId = customer
				localStorage.customerId = ""
			}
			else {
				this.globalService.user$.subscribe(userDetails => {
					if (userDetails) {
						this.customerId = userDetails['pk_id']
					}
				});
			}
		}
		this.walletService.transaction_details$.subscribe(
			data => {
				this.walletService.getWalletDetails(this.customerId).subscribe(
					data => {
						if (data !== false) {
							this.walletDetails = data
						}
					}
				)
			}
		)
  }

}
