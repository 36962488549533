import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    ValidationService,
    CustomerService,
    UsersService,
    RolesService,
    TicketingSystemService,
    ReminderService,
    ServicesService,
    // NotificationService,
    VendorModelService,
    CustomerWalletService
} from './index';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
    ],
    providers: [
        CustomerService,
        ValidationService,
        UsersService,
        RolesService,
        TicketingSystemService,
        ReminderService,
        ServicesService,
        // NotificationService,
        VendorModelService,
        CustomerWalletService
    ]
})
export class ServicesModule { }
