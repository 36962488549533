import { GetApiurl } from 'src/app/parameters';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

@Injectable()
export class VendorService {

    constructor(private http: HttpClient) { }
    // check email exist on overall application
    public get_vendor_details(id) {
        const url = GetApiurl(`vendor/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_vendor_list() {
        const url = GetApiurl(`vendor/get-vendors-list/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_supply_items(data) {
        const url = GetApiurl(`vendor/get-supply-items/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public update_vendor(data) {
        const url = GetApiurl(`vendor/${data.id}/`);
        return this.http
            .patch(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public update_vendor_details(data) {
        const url = GetApiurl(`vendor/${data.id}/`);
        return this.http
            .put(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_service_list() {
        const url = GetApiurl(`services/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public get_services(id) {
        const url = GetApiurl(`services/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public addAreas(data) {
        const url = GetApiurl(`vendor/provided-service/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public getVendorAreas(id, data) {
        const url = GetApiurl(`vendor/get-areas/${id}/`);
        return this.http
            .post(url, JSON.stringify(data))
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public vendorOfficeDetails(data, vendor?: any) {
        const url = GetApiurl(`vendor/vendor-office-details/?vendor=${vendor}`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public addVendorOficeProof(data, vendor?: any) {
        const url = GetApiurl(`vendor/vendor-office-proof/?vendor=${vendor}`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public uploadVendorOfficeDocuments(id, data: File, type) {
        // documents/(?P<vendor_id>[^/]+)

        let url = GetApiurl(`vendor/documents/${id}/`)
        return new Promise((resolve, reject) => {
            let formData: FormData = new FormData();
            let xhr: XMLHttpRequest = new XMLHttpRequest();
            if (data != undefined) {
                formData.append('file_data', data);
            }
            formData.append('type', type);
            formData.append('flag', 'web');
            xhr.open('POST', url, true);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            }
        });

    }
    public get_vendor_documents(id) {
        const url = GetApiurl(`vendor/get_documents/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_vendor_status(id) {
        const url = GetApiurl(`vendor/get_status/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_service_detail(id) {
        const url = GetApiurl(`vendor/get-service-details/${id}/`)

        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public get_food_service_type(service_detail_id) {
        let url = GetApiurl(`vendor/get-food-service-type/`)
        return this.http
            .get(url, { params: service_detail_id })
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public get_service_frequency(id) {
        // let service_type_id =         
        let url = GetApiurl(`service-frequency/get-service-type-frequency/`)
        return this.http
            .get(url, { params: id })
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public get_service_preference(id) {
        // let service_type_id = 
        let url = GetApiurl(`service-preference/get_service_type_preference/${id}/`)
        return this.http
            .get(url, { params: id })
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public get_service_items(id) {
        // let service_type_id = 
        let url = GetApiurl(`vendor/get-service-items/${id}/`)
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public add_service_items(data, id) {
        // let service_type_id = 
        let url = GetApiurl(`vendor/add-vendor-items/`)
        return this.http
            .post(url, data, { params: id })
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public removeVendorOfficeDocuments(id: any, data) {
        const url = GetApiurl(`vendor/remove-documents/${id}/`)
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }


    // public get_vendor_documents(id) {
    //     const url = GetApiurl(`vendor/get_documents/${id}`);
    //     return this.http
    //         .get(url)
    //         .pipe(map(Response => Response), catchError(this.handleError));
    // }

    addVendorServices(data) {
        let url = GetApiurl('vendor-service-selection/')
        return this.http.post(url, data)
            .pipe(map(Res => Res), catchError(this.handleError))
    }
    // Handeling error
    public handleError(error: Response | any) {
        let errMsg: string;
        let details;
        if (error instanceof Response) {
            const body = error.json() || "";
            const err = body["error"] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
            details = { detail: body, status: err.status };
        } else {
            errMsg = error.message ? error.message : error.toString();
            details = error.error;
        }
        return throwError(details);
    }

    public addSubServices(data, vendor?: any) {
        const url = GetApiurl(`vendor-services/?vendor=${vendor}`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_sub_services(id) {
        const url = GetApiurl(`vendor-services/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public update_vendor_service_details(data) {
        const url = GetApiurl(`vendor-services/get-update-vendors/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_vendor_services(id) {
        const url = GetApiurl(`vendor-services/vendor-service-details/?id=${id}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_vendor_service_details(vendor_id, id) {
        const url = GetApiurl(`vendor-services/${vendor_id}/get-vendors-list/${id}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public activateVendor(data, id) {
        const url = GetApiurl(`vendor/activate_vendor/${id}/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    //Staff related API
    public addVendorStaff(data, filedata) {
        const url = GetApiurl(`vendor-staff/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public Vendorjobcompletion(id, comment) {
        const url = GetApiurl(`ticket-detail/complete-staff-tickets/${id}/`);
        return this.http
            .post(url, comment)
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public verifyAndSaveStaff(data, filedata) {
        const url = GetApiurl(`vendor-staff/verify-staff-otp/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('profile', filedata, filedata.name);
                }
                for (var key in data) {
                    // check if the property/key is defined in the object itself, not in parent
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
        // return this.http        
        //     .post(url, data)
        //     .pipe(map(Response => Response), catchError(this.handleError));
    }

    public SaveAdminStaff(data, filedata) {
        const url = GetApiurl(`vendor-staff/admin-staff-creation/`);
        if (typeof filedata !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (filedata != undefined) {
                    formData.append('profile', filedata, filedata.name);
                }
                formData.append('data', JSON.stringify(data))
                xhr.open('POST', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
    }

    public checkStaffMobileExists(number) {
        const url = GetApiurl(`vendor-staff/check-staff-mobile-exists/`);
        let params = { number: number }
        return this.http
            .get(url, { params: params })
            .pipe(map(Response => Response), catchError(this.handleError));
    }
    public updateStaffImage(data, image?:any) {
        let value = data;
        const url = GetApiurl(`vendor-staff/${data.id}/`);
        if (typeof image !== 'undefined') {
            return new Promise((resolve, reject) => {
                const formData: any = new FormData();
                const xhr = new XMLHttpRequest();
                if (image != undefined) {
                    formData.append('profile', image, image.name);
                }
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        formData.append(key, data[key]);
                    }
                }
                xhr.open('PUT', url, true);
                xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
                xhr.send(formData);
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        resolve(JSON.parse(xhr.responseText));
                    }
                };
            });
        }
    }

    updateStaff(data){
        let value = data;
        const url = GetApiurl(`vendor-staff/${data.id}/`);
        return this.http
            .put(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }


    //Service Related API

    public get_vendor_po_detail(vendor?: any) {
        const url = GetApiurl(`vendor-po/?vendor=${vendor}`)

        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public retrieve_vendor_po_detail() {
        const url = GetApiurl(`vendor-po/retrieve-vendor-po/`)

        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public generate_po_number(id, data) {
        const url = GetApiurl(`vendor-po/generate-po-number/?vendor_po=${id}`)

        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public po_number_exists(id) {
        const url = GetApiurl(`vendor-po/po-number-exists/?id=${id}`)

        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public addMenuImage(formvalues, filedata: File, id, vendor?: any) {
        const url = GetApiurl(`vendor/add-vendor-items/${id}/?vendor=${vendor}`);
        // if (typeof filedata !== 'undefined') {
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('image', filedata, filedata.name);
            }
            formData.append('formValues', JSON.stringify(formvalues));
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
        // }
    }

    public activateVendorService(data) {
        const url = GetApiurl(`vendor-po/activate-service/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public removeMenuItem(id) {
        const url = GetApiurl(`vendor/remove-vendor-items/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public get_service_types(id, vendor_po?: any) {
        const url = GetApiurl(`vendor/get-service-types/${id}/?vendor_po=${vendor_po}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public updateServicePrice(data) {
        const url = GetApiurl(`vendor/update-service-price/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public updateSupplyPrice(data) {
        const url = GetApiurl(`vendor/update-supply-price/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public updateMenuImage(formvalues, filedata: File, id) {
        const url = GetApiurl(`vendor/update-vendor-items/${id}/`);
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata != undefined) {
                formData.append('image', filedata, filedata.name);
            }
            formData.append('formValues', JSON.stringify(formvalues));
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
    }
    // tslint:disable-next-line:variable-name
    public uploadVendorItem(service_type, filedata, id, action) {
        let url = '';
        if (action == 'vendor_item') {
             url = GetApiurl(`vendor/upload-vendor-item/${id}/`);
        }
        else{
             url = GetApiurl(`vendor/upload-supplyservice-item/${id}/`);
        }
        return new Promise((resolve, reject) => {
            const formData: any = new FormData();
            const xhr = new XMLHttpRequest();
            if (filedata !== undefined) {
                formData.append('file', filedata, filedata.name);
            }
            formData.append('data', JSON.stringify(service_type));
            formData.append('action', JSON.stringify(action));
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'JWT ' + localStorage.getItem('jwt_token'));
            xhr.send(formData);
            // tslint:disable-next-line:only-arrow-functions
            xhr.onreadystatechange = function () {
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    resolve(JSON.parse(xhr.responseText));
                }
            };
        });
    }
    public GetVendorItems(id, type) {
        const url = GetApiurl(`vendor/get-vendor-item/${id}/?service_type=${type}`);
        return this.http
            .get(url)
            .toPromise()
            .then((Response: any) => Response)
            .catch((err) => {
                this.handleError(err);
            });
    }
    public adjustQuantityTicket(data) {
        const url = GetApiurl(`vendor-tickets/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public vendorTicketsView(id) {
        const url = GetApiurl(`vendor-tickets/${id}`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }


    public vendorTicketAccepted(data) {
        const url = GetApiurl(`vendor-tickets/vendor-ticket-accept/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public vendorTicketRejected(data) {
        const url = GetApiurl(`vendor-tickets/vendor-ticket-reject/`);
        return this.http
            .post(url, data)
            .pipe(map(Response => Response), catchError(this.handleError));
    }


    public getVendorTickets(id) {
        const url = GetApiurl(`vendor-tickets/get-vendor-tickets/${id}/`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

    public getAlservUsers() {
        const url = GetApiurl(`vendor/get-alserv-users`);
        return this.http
            .get(url)
            .pipe(map(Response => Response), catchError(this.handleError));
    }

}
