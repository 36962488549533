import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GetApiurl } from 'src/app/parameters';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class TicketingSystemService {

    constructor(private http: HttpClient) { }

    protected handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            const body = error.json() || '';
            const err = body['error'] || JSON.stringify(body);
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else if (error instanceof HttpErrorResponse) {
            errMsg = error.error ? error.error : error.toString();
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        return throwError(errMsg);
    }

    private hidePopupForm: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public hidePopupForm$: Observable<any> = this.hidePopupForm.asObservable();


   public publishhidePopupForm(content) {
        this.hidePopupForm.next(content);
    }


    // ticket-master/
    public ticketMasterCreate(data) {
        const url: string = GetApiurl('ticket-master/')

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }

    public tempSecond(data) {
        const url: string = GetApiurl('ticket-master/second-step-store/')

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    // get-second-step-data
    public getTempSecond() {
        const url: string = GetApiurl('ticket-master/get-second-step-data/')

        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    public tempThird(data) {
        console.log(data)
        const url: string = GetApiurl('ticket-master/third-step-store/')

        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    // get-third-step-data
    public getThirdSecond() {
        const url: string = GetApiurl('ticket-master/get-third-step-data/')

        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    // vendorItems
    public vendorItems(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-vendor-items/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    public storeItems(data) {
        const url: string = GetApiurl(`ticket-master/store-items/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    public getStoredItems() {
        const url: string = GetApiurl(`ticket-master/get-stored-items/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getAllService() {
        const url: string = GetApiurl(`ticket-master/get-all-service/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getTicketDetail(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-ticket-detail/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getSubService(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-sub-service/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    public getServiceType(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-service-type/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getServicePreference(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-service-preference/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getServiceFrequency(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-service-frequency/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    public getTicketDetailInfo(id) {
        const url: string = GetApiurl(`ticket-detail/${id}/get-ticket-view/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public acceptOrReject(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/accept-or-reject/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }

    public createTicketdetailComments(data) {
        const url: string = GetApiurl('ticket-detail-comments/')
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }

    public getVendorList(data) {
        const url: string = GetApiurl(`ticket-master/vendor-list/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }

    public getTicketdetailComments(id) {
        const url: string = GetApiurl(`ticket-detail-comments/${id}/get-previous-comments/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getServicerList(id) {
        const url: string = GetApiurl(`ticket-master/${id}/get-servicer-list/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public getServicerCost(data) {
        const url: string = GetApiurl(`ticket-master/get-servicer-cost/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            )
    }
    public getMenuItems(id, vendor_id, vendor_po, params) {
        const url: string = GetApiurl(`ticket-master/${id}/menu-items/${vendor_id}/${vendor_po}/`, params);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public vendorItemsServiceType(id) {
        const url: string = GetApiurl(`ticket-master/${id}/vendor-items-service-type/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public AdminCreate(id) {
        const url: string = GetApiurl(`ticket-master/${id}/admin-create/`)
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public UpdateStatus(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/update-ticket/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public UpdateComment(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/comment-update/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public AssignToVendor(data) {
        const url: string = GetApiurl(`ticket-master/assign-vendor/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public AssignToVendorStaff(data) {
        const url: string = GetApiurl(`ticket-detail/assign-to-staff/`)
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public GetVendorGSTRate(id, sub_service) {
        const url: string = GetApiurl(`ticket-master/${id}/vendor-gst-rate/${sub_service}/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public CancleTicket(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/ticket-cancellation/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public CancelTicketDetail(id, data) {
        const url: string = GetApiurl(`ticket-detail/${id}/ticket-detail-cancellation/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    public membershipCheck() {
        const url: string = GetApiurl(`ticket-master/membership-check/`);
        return this.http.get(url)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    ticket_master_edit_amount_calculate(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/ticket-master-edit-amount/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    ticket_master_edit(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/ticket-master-edit/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    menuItemsList(id, data) {
        const url: string = GetApiurl(`ticket-master/${id}/menu-items-list/`);
        return this.http.post(url, data)
            .pipe(
                tap(res => {
                    return res;
                }),
                catchError(err => this.handleError(err))
            );
    }
    //get ticketdetail by ticketid
    getTicketDetailByTicket(ticketId) {
        const url: string = GetApiurl(`ticket-detail/${ticketId}/get-ticket-detail`);
        return this.http.get(url)
            .pipe(tap(res => {
                return res;
            }),
                catchError(err => this.handleError(err)));
    }
}
